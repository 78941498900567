import useSwr from 'swr';
import { errorFromWrappedError, k8sAlarmSubscriptionsApiRef } from '@netinsight/management-app-common-react';
import { useCallback } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import StatusCodes from 'http-status-codes';
import { AlarmSubscription } from '@netinsight/management-app-common-api';

export const AlarmSubscriptionCacheKey = 'alarmSubscriptions';

export const useAlarmSubscriptions = () => {
  const alarmSubscriptionApi = useApi(k8sAlarmSubscriptionsApiRef);

  const fetcher = useCallback(async (): Promise<AlarmSubscription[]> => {
    const alarmSubscriptionResponse = await alarmSubscriptionApi.listAlarmSubscriptions();

    if (alarmSubscriptionResponse.status !== StatusCodes.OK) {
      throw errorFromWrappedError(alarmSubscriptionResponse.status, alarmSubscriptionResponse.body);
    }

    return alarmSubscriptionResponse.body;
  }, [alarmSubscriptionApi]);

  return useSwr(AlarmSubscriptionCacheKey, fetcher, {
    refreshInterval: 5_000,
  });
};
