import React, { useCallback, useEffect, useMemo } from 'react';
import { useController, useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';
import { InfoCard, Progress, ResponseErrorPanel } from '@backstage/core-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button } from '@material-ui/core';
import { F } from '@mobily/ts-belt';
import {
  useFormStyles,
  useTimeTransferConfig,
  useTimeTransferConfigUpdate,
  CheckboxField,
  usePermission,
  useSubmitButtonProps,
} from '@netinsight/management-app-common-react';
import { FrequencyOutputSourceConfigSchema, TIMETRANSFER_DEFAULT_CONFIG } from '@netinsight/crds-timetransfer';
import { z } from 'zod';

type FrequencyOutFormValues = z.infer<typeof FrequencyOutputSourceConfigSchema>;

export const TimeNodeFrequencyOutTab = ({ nodeId }: { nodeId: string }) => {
  const { data: config, isLoading, error } = useTimeTransferConfig(nodeId);
  const { trigger: updateConfig, permission: canUpdatePermission } = useTimeTransferConfigUpdate(nodeId);
  const { isLoading: isLoadingPermission, ...permission } = usePermission(canUpdatePermission);
  const styles = useFormStyles();
  const initialFormValues = useMemo(() => config?.freqOut ?? TIMETRANSFER_DEFAULT_CONFIG.freqOut, [config]);

  const { control, reset, handleSubmit, formState } = useForm<FrequencyOutFormValues>({
    mode: 'onChange',
    defaultValues: initialFormValues,
    resolver: zodResolver(FrequencyOutputSourceConfigSchema),
  });
  const buttonProps = useSubmitButtonProps({ permission, formState });
  const prevFormValues = usePrevious(initialFormValues);
  useEffect(() => {
    if (!F.equals(prevFormValues, initialFormValues)) {
      reset(initialFormValues);
    }
  }, [initialFormValues, prevFormValues, reset]);

  const enableCheckboxFieldProps = useController({
    control,
    name: 'enabled',
  });

  const handleResult = useCallback(
    async (newConfig: FrequencyOutFormValues) => {
      return updateConfig(existingConfig => ({
        ...existingConfig,
        freqOut: newConfig,
      }));
    },
    [updateConfig],
  );

  if (isLoading || isLoadingPermission) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <InfoCard title="10M/Sync out" titleTypographyProps={{ component: 'h5' }}>
      <form onSubmit={handleSubmit(handleResult)} className={styles.formContainer}>
        <CheckboxField label="Enabled" fieldProps={enableCheckboxFieldProps} />
        <Box>
          <Button {...buttonProps} data-testid="btn-submit" />
        </Box>
      </form>
    </InfoCard>
  );
};
