/* eslint-disable no-nested-ternary */
import React, { FunctionComponent } from 'react';
import { useTheme } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import HelpIcon from '@material-ui/icons/Help';
import { G } from '@mobily/ts-belt';

export const LinkEndpointStableIcon: FunctionComponent<{ status?: boolean; title?: string }> = ({ status, title }) => {
  const theme = useTheme();
  if (G.isNullable(status)) {
    return (
      <HelpIcon
        fontSize="small"
        style={{
          color: theme.palette.grey[500],
          display: 'inline-block',
          position: 'relative',
          transform: 'translate(0px, 4px)',
        }}
        titleAccess={title ?? 'Status: Unknown'}
        aria-label="Status: Unknown"
      />
    );
  }
  return status ? (
    <ArrowUpwardIcon
      fontSize="small"
      style={{
        color: theme.palette.success.main,
        display: 'inline-block',
        position: 'relative',
        transform: 'translate(0px, 4px)',
      }}
      aria-label="Status: Up"
      titleAccess={title ?? 'Status: Up'}
    />
  ) : (
    <ArrowDownwardIcon
      fontSize="small"
      style={{
        color: theme.palette.error.main,
        display: 'inline-block',
        position: 'relative',
        transform: 'translate(0px, 4px)',
      }}
      aria-label="Status: Down"
      titleAccess={title ?? 'Status: Down'}
    />
  );
};
