import { useApi } from '@backstage/core-plugin-api';
import { GroupCalibrationOptions } from '@netinsight/group-calibration-api';
import { systemUpdate } from '@netinsight/zyntai-policy';
import { timenetManagerApiRef } from '../api/timenet-manager';
import { useCallback } from 'react';
import useSwrMutation from 'swr/mutation';
import { useSnackbarHelper } from '.';

export const useGroupCalibrationRun = (showNotification = true) => {
  const timenetManagerApi = useApi(timenetManagerApiRef);
  const { snackbar } = useSnackbarHelper(!showNotification);

  const run = useCallback(
    async (_: any, { arg: runOptions }: { arg: GroupCalibrationOptions }) => {
      return await timenetManagerApi.run(runOptions);
    },
    [timenetManagerApi],
  );

  const mutation = useSwrMutation('useGroupCalibrationResults', run, {
    onSuccess: () => {
      snackbar.success('Calibration task created');
    },
    onError: error => {
      snackbar.notifyError('Run', error.response, null);
    },
    revalidate: true,
    populateCache: false,
  });

  return { ...mutation, permission: systemUpdate };
};
