import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { useCallback } from 'react';
import useSWR, { SWRConfiguration } from 'swr';

export const useRoles = (
  swrConfig: SWRConfiguration = {
    shouldRetryOnError: true,
    refreshInterval: 0,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  },
) => {
  const identityApi = useApi(identityApiRef);

  const fetcher = useCallback(async () => {
    const backStageIdentity = await identityApi.getBackstageIdentity();
    return backStageIdentity?.ownershipEntityRefs ?? [];
  }, [identityApi]);
  return useSWR('useRoles', fetcher, swrConfig);
};
