import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { Button, Paper, TextField } from '@material-ui/core';
import NewRegionIcon from '@material-ui/icons/Add';
import { G } from '@mobily/ts-belt';
import { buttonPropsFromPermission, usePermission, useFormStyles } from '@netinsight/management-app-common-react';
import { SyncRegionName } from '@netinsight/crds';
import { TimeNodeSyncRegion } from '@netinsight/management-app-common-api';
import { useSyncRegionCreate } from '../../hooks';

export const SyncRegionCreateForm: FunctionComponent<{ syncRegions: TimeNodeSyncRegion[] }> = ({ syncRegions }) => {
  const formStyles = useFormStyles();
  const { trigger: createRegion, permission: createRegionPermission } = useSyncRegionCreate();
  const newRegionNameInputRef = useRef<HTMLInputElement>();
  const [fieldError, setFieldError] = useState<string>();
  const canCreate = usePermission(createRegionPermission);
  const handleAddNewRegion = useCallback(async () => {
    const existingSyncRegionNames = new Set(syncRegions.map(({ name }) => name));
    const newRegionNameValue = newRegionNameInputRef.current?.value;
    const parseResult = SyncRegionName.safeParse(newRegionNameValue);
    if (!parseResult.success) {
      setFieldError(parseResult.error.issues[0]?.message);
      return;
    }
    if (existingSyncRegionNames.has(parseResult.data)) {
      setFieldError('This name is already used for another sync region.');
      return;
    }
    setFieldError(undefined);
    await createRegion({ name: parseResult.data });
    if (newRegionNameInputRef.current) {
      newRegionNameInputRef.current.value = '';
    }
  }, [createRegion, syncRegions]);
  return (
    <Paper className={formStyles.formRow} style={{ padding: '1rem' }}>
      <TextField
        placeholder="New region name"
        helperText={fieldError}
        error={G.isNotNullable(fieldError)}
        inputRef={newRegionNameInputRef}
      />
      <Button
        variant="outlined"
        size="small"
        color="primary"
        type="button"
        data-testid="btn-add-region"
        startIcon={<NewRegionIcon />}
        style={{ alignSelf: 'flex-start' }}
        onClick={handleAddNewRegion}
        {...buttonPropsFromPermission(canCreate)}
      >
        Add new sync region
      </Button>
    </Paper>
  );
};
