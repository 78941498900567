import React from 'react';
import { InfoCard, Progress } from '@backstage/core-components';
import { Button, IconButton } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  apiPermission,
  buttonPropsFromPermission,
  errorFromWrappedError,
  k8sOnboardingApiRef,
  usePermission,
  useSnackbarHelper,
  useTimeNode,
} from '@netinsight/management-app-common-react';
import { onboardingApi as contract } from '@netinsight/management-app-common-api';
import { useApi } from '@backstage/core-plugin-api';

export const TimeNodeReplace = ({ nodeId }: { nodeId: string }) => {
  const onboardingApi = useApi(k8sOnboardingApiRef);
  const { snackbar } = useSnackbarHelper();
  const { isLoading: isLoadingPermission, ...permission } = usePermission(
    apiPermission(contract.createReplacementToken),
  );
  const [openTokenDialog, setOpenTokenDialog] = React.useState<boolean>(false);
  const [token, setToken] = React.useState<string>('');

  const { data: nodeState, isLoading } = useTimeNode(nodeId, { refreshInterval: 15_000 });

  const handleCloseTokenDialog = React.useCallback(() => {
    setOpenTokenDialog(false);
    setToken('');
  }, []);

  const handleTokenCopy = React.useCallback(async () => {
    await navigator.clipboard.writeText(token);
    snackbar.info('Copied token to clipboard');
  }, [snackbar, token]);

  const generateToken = React.useCallback(() => {
    setOpenTokenDialog(true);
    onboardingApi
      .createReplacementToken({ params: { nodeId } })
      .then(result => {
        if (result.status !== 200) {
          throw errorFromWrappedError(result.status, result.body);
        }
        setToken(result.body.access_token);
      })
      .catch(err => {
        snackbar.error(err.message);
        setOpenTokenDialog(false);
      });
  }, [onboardingApi, snackbar, nodeId]);

  const nodeName = nodeState?.spec.name ?? nodeId;
  const title = 'Replace';
  const subheader = `A replacement token is used to onboard a new timenode that will replace ${nodeName}. Timenode configuration will be preserved.`;

  if (isLoading || isLoadingPermission) {
    return (
      <InfoCard title={title} subheader={subheader}>
        <Progress />
      </InfoCard>
    );
  }

  return (
    <InfoCard title={title} subheader={subheader}>
      <Button
        variant="contained"
        size="large"
        color="secondary"
        onClick={generateToken}
        startIcon={<VpnKeyIcon />}
        data-testid="btn-show-replacement-token"
        {...buttonPropsFromPermission(permission)}
      >
        Issue a replacement token
      </Button>
      <Dialog open={openTokenDialog} onClose={handleCloseTokenDialog} data-testid="replacement-token-dialog">
        <DialogTitle>Token for replacing {nodeName}</DialogTitle>
        <DialogContent>
          {!token && <Progress />}
          {token && (
            <DialogContentText data-testid="token" style={{ overflowWrap: 'break-word', fontFamily: 'Monospace' }}>
              {token}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <IconButton title="Copy to clipboard" disabled={!token} size="small" onClick={handleTokenCopy}>
            <FileCopyIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </InfoCard>
  );
};
