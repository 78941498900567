import { ReactNode, useCallback } from 'react';
import { FieldPath, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { F, G, flow } from '@mobily/ts-belt';
import { SliderFieldProps } from '../components';

export const useSliderController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  description,
  defaultValue,
  min,
  max,
  step,
  toValueScale = F.identity,
  fromValueScale = F.identity,
  labelScale = flow(F.identity, String),
  marks,
  isRange = false,
  ...controllerInputs
}: UseControllerProps<TFieldValues, TName> & {
  label: string | ReactNode;
  description?: string;
  defaultValue?: number;
  min: number;
  max: number;
  step: number;
  toValueScale?: (val: number | number[]) => number | number[];
  fromValueScale?: (val: number | number[]) => number | number[];
  labelScale?: (val: number) => string;
  marks: Array<{ value: number; label: string }>;
  isRange?: boolean;
}): Partial<SliderFieldProps> & {
  label: string | ReactNode;
} => {
  const {
    field: { value, onChange: innerOnChange },
  } = useController(controllerInputs);
  const handleChange = useCallback(
    (_evt: any, val: any) => {
      if (isRange) {
        return innerOnChange(toValueScale(val));
      }
      return innerOnChange(toValueScale(G.isArray(val) && val.length ? val[0] : val));
    },
    [innerOnChange, toValueScale, isRange],
  );
  return {
    value: fromValueScale(value),
    onChange: handleChange as SliderFieldProps['onChange'],
    defaultValue: defaultValue,
    label,
    min,
    max,
    step,
    // @ts-expect-error this is normal due to array and number confused might be improved for reusability
    valueScale: toValueScale,
    labelScale,
    marks,
    description,
    name: controllerInputs?.name ?? '',
  };
};
