import { initContract } from '@ts-rest/core';
import StatusCodes from 'http-status-codes';
import { WrappedErrorSchema } from './types';
import { AlarmSubscriptionSpecSchema, AlarmSubscriptionStatusSchema, AlarmSubscriptionSpec } from '@netinsight/crds';
import z from 'zod';
import { systemCreate, systemDelete, systemRead } from '@netinsight/zyntai-policy';

const c = initContract();

export const AlarmSubscriptionSchema = z.object({
  id: z.string(),
  spec: AlarmSubscriptionSpecSchema,
  status: AlarmSubscriptionStatusSchema,
});

export type AlarmSubscription = z.infer<typeof AlarmSubscriptionSchema>;
export type { AlarmSubscriptionSpec };
export const alarmSubscriptionApi = c.router({
  listAlarmSubscriptions: {
    method: 'GET',
    path: '/alarm/subscriptions',
    summary: 'List alarm subscriptions',
    responses: {
      [StatusCodes.OK]: z.array(AlarmSubscriptionSchema),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemRead } as const,
  },
  getAlarmSubscription: {
    method: 'GET',
    path: '/alarm/subscriptions/:id',
    summary: 'Get alarm subscription',
    responses: {
      [StatusCodes.OK]: AlarmSubscriptionSchema,
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemRead } as const,
  },
  deleteAlarmSubscription: {
    method: 'DELETE',
    path: '/alarm/subscriptions/:id',
    summary: 'Remove alarm subscription',
    body: null,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemDelete } as const,
  },
  createAlarmSubscription: {
    method: 'POST',
    path: '/alarm/subscriptions',
    summary: 'Create alarm subscription token',
    body: AlarmSubscriptionSpecSchema,
    responses: {
      [StatusCodes.OK]: z.string(),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
    metadata: { permission: systemCreate } as const,
  },
});
