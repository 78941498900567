import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import { TableProps } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sNodeApiRef } from '@netinsight/management-app-common-react';
import {
  NodeQueryParameters,
  NodeQuerySortableColumns,
  TimeNodeQueryResultItem,
} from '@netinsight/management-app-common-api';

type QueryCallback = Exclude<TableProps<TimeNodeQueryResultItem>['data'], TimeNodeQueryResultItem[]>;

export const useTimeNodesTableQueryCallback = (): QueryCallback => {
  const nodeApi = useApi(k8sNodeApiRef);

  return useCallback<QueryCallback>(
    async tableQueryParams => {
      const filtersByColumnName = Object.fromEntries(
        tableQueryParams.filters.map(f => [f.column.field, f.value] as const),
      );

      const apiQueryParams: NodeQueryParameters = {
        nodeName: filtersByColumnName.name,
        syncRegions: filtersByColumnName.syncRegion,
        controlStates: filtersByColumnName.controlState,
        lifecycleStateHeartbeatStatuses: filtersByColumnName.lifecycleStateHeartbeatStatus,
        inputs: filtersByColumnName.inputs,
        outputs: filtersByColumnName.outputs,
        limit: tableQueryParams.pageSize,
        offset: tableQueryParams.pageSize * tableQueryParams.page,
        orderBy: (tableQueryParams.orderBy.field as NodeQuerySortableColumns) ?? 'name',
        orderDirection: tableQueryParams.orderDirection,
      };
      const result = await nodeApi.queryNodes({ query: apiQueryParams });
      if (result.status !== StatusCodes.OK) {
        throw errorFromWrappedError(result.status, result.body);
      }
      return {
        data: result.body.result,
        page: result.body.metadata.page,
        totalCount: result.body.metadata.totalCount,
      };
    },
    [nodeApi],
  );
};
