import { FunctionComponent, useCallback, useContext } from 'react';
import { generateLinkName } from '../../utils';
import { useFormContext } from 'react-hook-form';
import React from 'react';
import {
  buttonPropsFromPermission,
  PermissionResult,
  TextField,
  useFormStyles,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import { PersistedSyncLink, PersistedSyncLinkSchema } from '@netinsight/management-app-common-api';
import { Button } from '@material-ui/core';
import { LinkDetailContext } from '../../constants';

export const LinkName: FunctionComponent<{ permission: PermissionResult }> = ({ permission }) => {
  const { setValue, getValues, control } =
    useFormContext<Pick<PersistedSyncLink, 'name' | 'endpointA' | 'endpointB'>>();
  const formStyles = useFormStyles();
  const linkDetailContextValue = useContext(LinkDetailContext);
  const { field: nameInputProps } = useTextFieldController({
    control,
    name: 'name',
    label: 'Name',
    placeholder: 'Auto',
    schema: PersistedSyncLinkSchema.shape.name,
  });
  const handleGenerateLinkName = useCallback(() => {
    const vals = getValues();
    const newName = generateLinkName(vals.endpointA, vals.endpointB, linkDetailContextValue.nodeNameMap ?? {}) ?? '';
    setValue('name', newName);
  }, [getValues, setValue, linkDetailContextValue.nodeNameMap]);

  return (
    <div className={formStyles.formRow} style={{ flexWrap: 'nowrap' }}>
      <TextField fullWidth multiline {...nameInputProps} style={{ flex: 'auto' }} />
      <Button
        type="button"
        variant="outlined"
        size="small"
        color="default"
        onClick={handleGenerateLinkName}
        {...buttonPropsFromPermission(permission)}
      >
        Generate
      </Button>
    </div>
  );
};
