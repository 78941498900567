import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ResponseErrorPanel, Progress, InfoCard, Link } from '@backstage/core-components';
import { TextField, Button, Grid, Box, Divider, Typography } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { NodeManagerConfig } from '@netinsight/management-app-common-api';
import {
  useNodeManagerConfig,
  InputTooltip,
  useFormStyles,
  usePermission,
  useNodeManagerConfigUpdate,
  useSubmitButtonProps,
} from '@netinsight/management-app-common-react';
import { SSHKeysConfigForm } from '@netinsight/plugin-global-settings-ui';

interface TimeNodeAuthenticationConfigProps {
  nodeId: string;
}

type TimeNodeSystemAuthenticationConfig = Required<
  Pick<NonNullable<NodeManagerConfig['system']>, 'sshKeys' | 'adminPassword'>
>;

function mergeNodeManagerConfig(
  config: NodeManagerConfig,
  sshKeysConfig: TimeNodeSystemAuthenticationConfig,
): NodeManagerConfig {
  const { system: oldSystem } = config;
  const sshKeys = sshKeysConfig.sshKeys.map(sshKeyConfig => {
    const { expiryTime, ...rest } = sshKeyConfig;
    // omit null values that may appear due to clearing expiry date field
    return {
      ...rest,
      expiryTime: expiryTime || undefined,
    };
  });
  const { adminPassword } = sshKeysConfig;
  const system = { ...oldSystem, adminPassword, sshKeys };
  return {
    ...config,
    system,
  };
}

export const TimeNodeAuthenticationConfig = ({ nodeId }: TimeNodeAuthenticationConfigProps) => {
  const {
    data: nodeManagerConfig,
    error: fetchError,
    isLoading: isLoadingNodeManagerConfig,
  } = useNodeManagerConfig(nodeId);
  const { trigger: uploadConfig, permission: updatePermission } = useNodeManagerConfigUpdate(nodeId);
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updatePermission);
  const defaultValues = useMemo(() => {
    return {
      sshKeys: nodeManagerConfig?.system?.sshKeys || [],
      adminPassword: nodeManagerConfig?.system?.adminPassword,
    };
  }, [nodeManagerConfig]);

  const formMethods = useForm<TimeNodeSystemAuthenticationConfig>({
    mode: 'onChange',
    defaultValues,
  });

  const { control, reset: resetForm, handleSubmit, formState } = formMethods;
  const buttonProps = useSubmitButtonProps({ permission, formState });
  const submitConfig = useCallback(
    async (newSshKeyConfig: any) => await uploadConfig(cfg => mergeNodeManagerConfig(cfg, newSshKeyConfig)),
    [uploadConfig],
  );

  // Make sure default values are set correctly
  useEffect(() => {
    resetForm(defaultValues);
  }, [resetForm, defaultValues]);

  const styles = useFormStyles();

  if (fetchError) {
    return <ResponseErrorPanel error={fetchError} />;
  }

  if (isLoadingNodeManagerConfig || isLoadingPermission) {
    return <Progress />;
  }

  return (
    <InfoCard title="Authentication Configuration">
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitConfig)} noValidate>
          <Grid container direction="column">
            <Box width="200px">
              <Controller
                name="adminPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    type="password"
                    label="Admin Password"
                    id="adminPassword"
                    variant="filled"
                    InputProps={{
                      endAdornment: (
                        <InputTooltip text="For enhanced security, it is strongly recommended to utilize SSH keys instead of an admin password." />
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            <div className={styles.formContainer}>
              <Alert severity="info">
                <Typography variant="body2">
                  SSH keys specific to this node are managed here. Global SSH keys, which apply to all nodes, are
                  managed in the <Link to="/system/global-defaults/ssh-keys">Global Settings page</Link>.
                </Typography>
              </Alert>
              <SSHKeysConfigForm permission={permission} />
            </div>
            <Box marginTop={2} className={styles.buttonContainer}>
              <Button {...buttonProps} size="medium" data-testid="btn-submit-authentication-form" />
              <Button
                color="default"
                size="medium"
                variant="outlined"
                onClick={() => resetForm(defaultValues)}
                data-testid="btn-cancel-authentication-form"
                disabled={buttonProps.disabled || !formState.isDirty}
              >
                Reset
              </Button>
            </Box>
          </Grid>
        </form>
      </FormProvider>
    </InfoCard>
  );
};
