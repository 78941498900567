import { Page, TabbedLayout } from '@backstage/core-components';
import React from 'react';
import { HeaderWithAlarms } from '../HeaderWithAlarms/HeaderWithAlarms';
import { LinksPage } from '@netinsight/plugin-time-transfer-ui';
import { SyncInputsUiPage } from '@netinsight/plugin-sync-inputs-ui';
import { GroupCalibrationPage } from '../GroupCalibrationPage';
import { NestedTabLayout } from '@netinsight/management-app-common-react';
import { Navigate, Route, Routes } from 'react-router';
import { NodeGraphPageRedirector } from '@netinsight/plugin-node-graph-ui';
import { SyncRegionConfigPage, SyncRegionStatusPage } from '@netinsight/plugin-sync-region-ui';
import { CalibrationGroups } from './CalibrationTab/GroupsTab/CalibrationGroups';

const tabStyle = {
  height: '4.25rem',
};

export const TimeNetworkPage = () => {
  return (
    <Page themeId="home">
      <HeaderWithAlarms title="Zyntai Time Network" />
      <TabbedLayout>
        <TabbedLayout.Route path="/" title="Links" tabProps={{ id: 'time-nodes-links-tab', style: tabStyle }}>
          <LinksPage />
        </TabbedLayout.Route>
        <TabbedLayout.Route
          path="/sources"
          title="Sync Sources"
          tabProps={{ id: 'time-nodes-sync-sources', style: tabStyle }}
        >
          <SyncInputsUiPage />
        </TabbedLayout.Route>
        <TabbedLayout.Route
          path="/sync-regions"
          title="Sync regions"
          tabProps={{ id: 'sync-regions-tab', style: tabStyle }}
        >
          <NestedTabLayout>
            <NestedTabLayout.Route index title="Index" element={<Navigate to="status" />} />
            <NestedTabLayout.Route path="status" title="Status" element={<SyncRegionStatusPage />} />
            <NestedTabLayout.Route path="config" title="Config" element={<SyncRegionConfigPage />} />
          </NestedTabLayout>
        </TabbedLayout.Route>
        <TabbedLayout.Route
          path="/graph/"
          title="Topology"
          tabProps={{ id: 'time-nodes-topology-tab', style: tabStyle }}
        >
          <Routes>
            <Route path=":graphId?" element={<NodeGraphPageRedirector />} />
          </Routes>
        </TabbedLayout.Route>
        <TabbedLayout.Route
          path="/calibration"
          title="Calibration"
          tabProps={{ id: 'time-nodes-calibration-tab', style: tabStyle }}
        >
          <NestedTabLayout>
            <NestedTabLayout.Route index title="Index" element={<Navigate to="status" />} />
            <NestedTabLayout.Route path="status" title="Status" element={<GroupCalibrationPage />} />
            <NestedTabLayout.Route path="presets" title="Groups" element={<CalibrationGroups />} />
          </NestedTabLayout>
        </TabbedLayout.Route>
      </TabbedLayout>
    </Page>
  );
};
