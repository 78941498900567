import React, { FunctionComponent, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { Divider, Hidden, makeStyles, Paper, Typography } from '@material-ui/core';
import { G } from '@mobily/ts-belt';
import {
  DynamicLinkChangeThresholdPointSchema,
  DynamicLinkNoiseParametersSchema,
  DynamicLinkThresholdSchema,
  LinkOption,
} from '@netinsight/crds-timetransfer';
import { PersistedSyncLink, PersistedSyncLinkSchema } from '@netinsight/management-app-common-api';
import {
  FeatureFlags,
  NetiHeadingTypographyProps,
  RadioSingle,
  TextField,
  useFormStyles,
  useRadioSingleController,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import { FeatureFlagged } from '@backstage/core-app-api';

const useStyles = makeStyles(theme => ({
  rowLabel: {
    display: 'block',
    minWidth: '4rem',
    fontWeight: 'bold',
    ['& [aria-hidden]']: {},
  },
  fieldsetHeading: {
    fontSize: '1.0625rem',
    fontWeight: 'bold',
  },
  choiceGroup: {
    display: 'flex',
    gap: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

type HeadingLevel = 3 | 4;

export const LinkOptionsFormContent: FunctionComponent<{
  defaultOptions: LinkOption;
  subtitle?: ReactNode;
  headingLevel: HeadingLevel;
}> = ({ defaultOptions, subtitle, headingLevel }) => {
  const headingTag = `h${headingLevel}` as const;
  const styles = useStyles();
  const formStyles = useFormStyles();
  const linkOptionsSchema = PersistedSyncLinkSchema.shape.options.unwrap();
  const { control } = useFormContext<PersistedSyncLink>();

  const { field: dynamicLinkChangeThresholdProps } = useRadioSingleController({
    control,
    name: 'options.dynamicLinkChangeThreshold',
    defaultValue: defaultOptions.dynamicLinkChangeThreshold,
  });

  const { field: dynamicLinkNoiseProps } = useRadioSingleController({
    control,
    name: 'options.useDynamicLinkNoise',
    defaultValue: defaultOptions.useDynamicLinkNoise,
  });

  const { field: dynamicThresholdGoodSigmaProps } = useTextFieldController({
    schema: DynamicLinkChangeThresholdPointSchema.shape.sigma,
    control,
    name: 'options.dynamicLinkChangeThresholdParams.good.sigma',
    label: 'Sigma',
    placeholder: defaultOptions.dynamicLinkChangeThresholdParams?.good?.sigma?.toString(),
  });

  const { field: dynamicThresholdGoodHProps } = useTextFieldController({
    schema: DynamicLinkChangeThresholdPointSchema.shape.h,
    control,
    name: 'options.dynamicLinkChangeThresholdParams.good.h',
    label: 'H',
    placeholder: defaultOptions.dynamicLinkChangeThresholdParams?.good?.h?.toString(),
  });

  const { field: dynamicThresholdBadSigmaProps } = useTextFieldController({
    schema: DynamicLinkChangeThresholdPointSchema.shape.sigma,
    control,
    name: 'options.dynamicLinkChangeThresholdParams.bad.sigma',
    label: 'Sigma',
    placeholder: defaultOptions.dynamicLinkChangeThresholdParams?.bad?.sigma?.toString(),
  });

  const { field: dynamicThresholdBadHProps } = useTextFieldController({
    schema: DynamicLinkChangeThresholdPointSchema.shape.h,
    control,
    name: 'options.dynamicLinkChangeThresholdParams.bad.h',
    label: 'H',
    placeholder: defaultOptions.dynamicLinkChangeThresholdParams?.bad?.h?.toString(),
  });

  const { field: dynamicThresholdMinimumProps } = useTextFieldController({
    schema: DynamicLinkThresholdSchema.shape.minimum,
    control,
    name: 'options.dynamicLinkChangeThresholdParams.minimum',
    label: 'Minimum',
    placeholder: defaultOptions.dynamicLinkChangeThresholdParams?.minimum?.toString(),
  });

  const { field: dynamicThresholdMaximumProps } = useTextFieldController({
    schema: DynamicLinkThresholdSchema.shape.maximum,
    control,
    name: 'options.dynamicLinkChangeThresholdParams.maximum',
    label: 'Maximum',
    placeholder: defaultOptions.dynamicLinkChangeThresholdParams?.maximum?.toString(),
  });

  const { field: linkChangeThresholdProps } = useTextFieldController({
    schema: linkOptionsSchema.shape.linkChangeThreshold,
    control,
    name: 'options.linkChangeThreshold',
    label: 'Value',
    placeholder: defaultOptions.linkChangeThreshold?.toString(),
  });

  const { field: linkNoiseProps } = useTextFieldController({
    schema: linkOptionsSchema.shape.linkNoise,
    control,
    name: 'options.linkNoise',
    label: 'Value',
    placeholder: defaultOptions.linkNoise?.toString(),
  });

  const { field: dynamicLinkNoiseMinLinkNoiseProps } = useTextFieldController({
    schema: DynamicLinkNoiseParametersSchema.shape.minLinkNoise,
    control,
    name: 'options.dynamicLinkNoise.minLinkNoise',
    label: 'Minimum',
    placeholder: defaultOptions.dynamicLinkNoise?.minLinkNoise?.toString(),
  });

  const { field: dynamicLinkNoiseFactorProps } = useTextFieldController({
    schema: DynamicLinkNoiseParametersSchema.shape.factor,
    control,
    name: 'options.dynamicLinkNoise.factor',
    label: 'Factor',
    placeholder: defaultOptions.dynamicLinkNoise?.factor?.toString(),
  });

  const { field: profileRttMismatchProps } = useTextFieldController({
    control,
    name: 'options.profileMaxRttMismatch',
    schema: linkOptionsSchema.shape.profileMaxRttMismatch,
    label: 'Profile max RTT mismatch',
    placeholder: defaultOptions.profileMaxRttMismatch?.toString(),
  });

  return (
    <>
      {G.isNotNullable(subtitle) ? (
        <Typography variant="subtitle2" component="p">
          {subtitle}
        </Typography>
      ) : null}
      <Divider variant="fullWidth" style={{ width: '100%' }} />
      <Typography {...NetiHeadingTypographyProps[headingTag]}>Link change threshold</Typography>
      <div className={styles.choiceGroup}>
        <Paper role="group" aria-labelledby="dynamicLinkChangeThresholdDynamicLabel" className={formStyles.formSection}>
          <div className={formStyles.formRow}>
            <RadioSingle
              value
              label="Dynamic"
              labelClassName={styles.fieldsetHeading}
              {...dynamicLinkChangeThresholdProps}
              LabelProps={{ id: 'dynamicLinkChangeThresholdDynamicLabel' }}
            />
          </div>
          <div className={formStyles.formRow} role="group" aria-labelledby="dynamicLinkChangeThresholdParams.good">
            <Typography
              id="dynamicLinkChangeThresholdParams.good"
              variant="body1"
              className={styles.rowLabel}
              component="span"
            >
              Good
            </Typography>
            <div className={formStyles.formRow}>
              <TextField
                {...dynamicThresholdGoodSigmaProps}
                unit="μs"
                inputProps={{ ...dynamicThresholdGoodSigmaProps.inputProps, type: 'text' }}
              />
              <TextField
                {...dynamicThresholdGoodHProps}
                unit="μs"
                inputProps={{ ...dynamicThresholdGoodHProps.inputProps, type: 'text' }}
              />
            </div>
          </div>
          <div className={formStyles.formRow} role="group" aria-labelledby="dynamicLinkChangeThresholdParams.bad">
            <Typography
              id="dynamicLinkChangeThresholdParams.bad"
              variant="body1"
              className={styles.rowLabel}
              component="span"
            >
              Bad
            </Typography>
            <div className={formStyles.formRow}>
              <TextField
                {...dynamicThresholdBadSigmaProps}
                unit="μs"
                inputProps={{ ...dynamicThresholdBadSigmaProps.inputProps, type: 'text' }}
              />
              <TextField
                {...dynamicThresholdBadHProps}
                unit="μs"
                inputProps={{ ...dynamicThresholdBadHProps.inputProps, type: 'text' }}
              />
            </div>
          </div>
          <div className={formStyles.formRow}>
            {/* @ts-expect-error: bug with component definition */}
            <Hidden smDown>
              <span className={styles.rowLabel} aria-hidden />
            </Hidden>
            <div className={formStyles.formRow}>
              <TextField
                {...dynamicThresholdMinimumProps}
                unit="μs"
                inputProps={{ ...dynamicThresholdMinimumProps.inputProps, type: 'text' }}
              />
              <TextField
                {...dynamicThresholdMaximumProps}
                unit="μs"
                inputProps={{ ...dynamicThresholdMaximumProps.inputProps, type: 'text' }}
              />
            </div>
          </div>
        </Paper>
        <Paper role="group" aria-labelledby="dynamicLinkChangeThresholdFixedLabel" className={formStyles.formSection}>
          <div className={formStyles.formRow}>
            <RadioSingle
              value={false}
              label="Fixed"
              labelClassName={styles.fieldsetHeading}
              {...dynamicLinkChangeThresholdProps}
              LabelProps={{ id: 'dynamicLinkChangeThresholdFixedLabel' }}
            />
          </div>
          <div className={formStyles.formRow}>
            <TextField
              {...linkChangeThresholdProps}
              unit="μs"
              inputProps={{ ...linkChangeThresholdProps.inputProps, type: 'text' }}
            />
          </div>
        </Paper>
      </div>
      <FeatureFlagged with={FeatureFlags.ShowExperimentalFeatures}>
        <Divider variant="fullWidth" style={{ width: '100%' }} />
        <Typography {...NetiHeadingTypographyProps[headingTag]}>Link noise</Typography>
        <div className={styles.choiceGroup}>
          <Paper role="group" aria-labelledby="dynamicLinkNoiseLabel" className={formStyles.formSection}>
            <div className={formStyles.formRow}>
              <RadioSingle
                value
                label="Dynamic"
                labelClassName={styles.fieldsetHeading}
                {...dynamicLinkNoiseProps}
                LabelProps={{ id: 'dynamicLinkNoiseLabel' }}
              />
            </div>
            <div className={formStyles.formRow}>
              <TextField
                {...dynamicLinkNoiseMinLinkNoiseProps}
                unit="μs"
                inputProps={{ ...dynamicLinkNoiseMinLinkNoiseProps.inputProps, type: 'text' }}
              />
              <TextField
                {...dynamicLinkNoiseFactorProps}
                inputProps={{ ...dynamicLinkNoiseFactorProps.inputProps, type: 'text' }}
              />
            </div>
          </Paper>
          <Paper role="group" aria-labelledby="fixedLinkNoiseLabel" className={formStyles.formSection}>
            <div className={formStyles.formRow}>
              <RadioSingle
                value={false}
                label="Fixed"
                labelClassName={styles.fieldsetHeading}
                {...dynamicLinkNoiseProps}
                LabelProps={{ id: 'fixedLinkNoiseLabel' }}
              />
            </div>
            <div className={formStyles.formRow}>
              <TextField {...linkNoiseProps} unit="μs" inputProps={{ ...linkNoiseProps.inputProps, type: 'text' }} />
            </div>
          </Paper>
        </div>
      </FeatureFlagged>
      <Divider variant="fullWidth" style={{ width: '100%' }} />
      <FeatureFlagged without={FeatureFlags.ShowExperimentalFeatures}>
        <TextField
          {...linkNoiseProps}
          label="Link noise"
          unit="μs"
          inputProps={{ ...linkNoiseProps.inputProps, type: 'text' }}
        />
      </FeatureFlagged>
      <TextField
        {...profileRttMismatchProps}
        unit="μs"
        inputProps={{ ...profileRttMismatchProps.inputProps, type: 'text' }}
      />
    </>
  );
};
