import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwrMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { TimeNodeSyncRegion, syncRegionApi as contract } from '@netinsight/management-app-common-api';
import {
  apiPermission,
  errorFromWrappedError,
  k8sSyncRegionApiRef,
  useSnackbarHelper,
} from '@netinsight/management-app-common-react';
import { SyncRegionSwrKey } from './useSyncRegions';

export const useSyncRegionDelete = () => {
  const syncRegionApi = useApi(k8sSyncRegionApiRef);
  const { snackbar } = useSnackbarHelper();

  const remove = useCallback(
    async (_: string, { arg: { id } }: { arg: Pick<TimeNodeSyncRegion, 'id'> }) => {
      const removeRegionResponse = await syncRegionApi.removeSyncRegion({
        params: { syncRegionId: id },
      });
      if (removeRegionResponse.status === StatusCodes.OK) {
        return [{ id }];
      }
      throw errorFromWrappedError(removeRegionResponse.status, removeRegionResponse.body);
    },
    [syncRegionApi],
  );
  const mutation = useSwrMutation(SyncRegionSwrKey, remove, {
    revalidate: true,
    onSuccess: () => {
      snackbar.notifySuccess('Remove');
    },
    onError: error => {
      snackbar.notifyError('Remove', error.response, null);
    },
  });
  return { ...mutation, permission: apiPermission(contract.removeSyncRegion) };
};
