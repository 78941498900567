import { scaleOrdinal } from 'd3-scale';
import React, { FunctionComponent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import { A, G } from '@mobily/ts-belt';
import { PersistedSyncLink, PersistedSyncLinkSchema } from '@netinsight/management-app-common-api';
import {
  SliderField,
  useSliderController,
  useTextFieldController,
  TextField,
  useFormStyles,
} from '@netinsight/management-app-common-react';

const minPacketSize = PersistedSyncLinkSchema.shape.packetSize.unwrap().removeDefault().minValue;

const packetRateMapping: [number, string][] = [
  [32, '1k'],
  [28, '1.1k'],
  [25, '1.3k'],
  [20, '1.6k'],
  [16, '2k'],
  [13, '2.5k'],
  [10, '3.2k'],
  [9, '3.6k'],
  [8, '4k'],
  [7, '4.6k'],
  [6, '5.3k'],
  [5, '6.4k'],
  [4, '8k'],
  [3, '10.7k'],
  [2, '16k'],
  [1, '32k'],
];
const defaultDivisorValue = 4;
const defaultDivisorIndex = packetRateMapping.findIndex(([divisor]) => divisor === defaultDivisorValue);
const packetPerSecScale = scaleOrdinal(
  ...A.unzip(packetRateMapping.map(([, packetPerSec], index) => [index, packetPerSec])),
);

const sliderProps = {
  min: 0,
  max: packetRateMapping.length - 1,
  step: 1,
  fromValueScale: (val: number | number[]) =>
    scaleOrdinal(...A.unzip(packetRateMapping.map(([divisor], index) => [divisor, index])))(val as number),
  toValueScale: (val: number | number[]) =>
    scaleOrdinal(...A.unzip(packetRateMapping.map(([divisor], index) => [index, divisor])))(val as number),
  labelScale: packetPerSecScale,
  marks: [32, 4, 1]
    .map(markVal => packetRateMapping.findIndex(([mappingVal]) => mappingVal === markVal))
    .map(markValIndex => ({
      value: markValIndex,
      label: packetPerSecScale(markValIndex) + (defaultDivisorIndex === markValIndex ? ' (default)' : ''),
    })),
};

const formatBitrate = (bitRate: number | undefined) => {
  if (G.isNullable(bitRate) || !G.isNumber(bitRate) || isNaN(bitRate)) {
    return undefined;
  }
  if (bitRate === 0) {
    return '0kbps';
  }
  const units = ['kbps', 'Mbps', 'Gbps', 'Tbps'];
  let i = -1;
  let roundedBitRate = bitRate;
  do {
    roundedBitRate = roundedBitRate / 1000;
    i++;
  } while (roundedBitRate > 1000);

  if (i >= units.length) return undefined;

  return `${Math.max(roundedBitRate, 0.1).toFixed(2).replace(/0+$/, '').replace(/\.$/, '')}${units[i]}`;
};

const calculateBandwidth = (tsRateDivisor?: number, packetSize?: number) =>
  G.isNumber(tsRateDivisor) && G.isNumber(packetSize)
    ? (packetSize + 18) * 8 * ((32 / tsRateDivisor) * 1e3)
    : undefined;

export const LinkBandwidthFormContent: FunctionComponent = () => {
  const formStyles = useFormStyles();
  const { control } = useFormContext<PersistedSyncLink>();
  const { field: packetSizeProps } = useTextFieldController({
    control,
    name: 'packetSize',
    label: 'IP packet size',
    placeholder: minPacketSize?.toString(),
    schema: PersistedSyncLinkSchema.shape.packetSize,
  });
  const timestampRateSliderProps = useSliderController({
    control,
    name: 'timestampRateDivisor',
    label: 'Timestamp rate (pkt/s)',
    description: 'Number of packets generated per second',
    ...sliderProps,
  });
  const tsRateVal = useWatch({
    control,
    name: 'timestampRateDivisor',
  });
  const packetSizeVal = useWatch({
    control,
    name: 'packetSize',
  });
  const bandWidth = calculateBandwidth(tsRateVal, packetSizeVal ?? minPacketSize!);

  return (
    <>
      <SliderField {...timestampRateSliderProps} />
      <TextField fullWidth {...packetSizeProps} unit="bytes" />
      {G.isNotNullable(bandWidth) ? (
        <div className={formStyles.formRow}>
          <Typography variant="body1" color="textSecondary">
            Bandwidth:
          </Typography>
          <Typography variant="body1">{formatBitrate(bandWidth) ?? '-'}</Typography>
        </div>
      ) : null}
    </>
  );
};
