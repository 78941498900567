import React from 'react';
import { FormState } from 'react-hook-form';
import { ButtonProps, CircularProgress } from '@material-ui/core';
import { isNullableOrEmpty } from '../utils';
import { PermissionResult } from './usePermission';

const getGenericPermissionTooltipText = (name?: string) =>
  isNullableOrEmpty(name)
    ? `This operation requires permissions that your current profile does not have`
    : `This operation requires the permission for ${name}, which your current profile does not have`;

export const buttonPropsFromPermission = (
  { isAllowed, name }: PermissionResult,
  formState?: FormState<any>,
): Pick<ButtonProps, 'disabled' | 'title'> => ({
  disabled: !isAllowed || formState?.isSubmitting === true,
  title: isAllowed ? undefined : getGenericPermissionTooltipText(name),
});

export const useSubmitButtonProps = ({
  permission,
  formState,
}: {
  permission?: PermissionResult;
  formState?: FormState<any>;
}): ButtonProps => {
  const isSubmitting = formState?.isSubmitting === true;
  const isAllowed = permission?.isAllowed === true;
  return {
    title: !isAllowed ? getGenericPermissionTooltipText(permission?.name) : undefined,
    disabled: !isAllowed || isSubmitting,
    color: 'primary',
    variant: 'contained',
    size: 'large',
    type: 'submit',
    startIcon: isSubmitting ? React.createElement(CircularProgress, { size: '1rem' }) : null,
    children: 'Apply',
  };
};
