import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import { TableProps } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sTTLinksApiRef } from '@netinsight/management-app-common-react';
import {
  PersistedSyncLinkQueryParameters,
  PersistedSyncLinkQueryResultItem,
  PersistedSyncLinkQuerySortableColumnsType,
} from '@netinsight/management-app-common-api';

type QueryCallback = Exclude<TableProps<PersistedSyncLinkQueryResultItem>['data'], PersistedSyncLinkQueryResultItem[]>;

export const useLinkTableQueryCallback = ({ syncRegions }: { syncRegions?: string[] }): QueryCallback => {
  const ttlinksApi = useApi(k8sTTLinksApiRef);
  const fetcher = useCallback<QueryCallback>(
    async queryParams => {
      const filtersByColumnName = Object.fromEntries(queryParams.filters.map(f => [f.column.field, f.value] as const));
      const apiQueryParams: PersistedSyncLinkQueryParameters = {
        name: filtersByColumnName.name,
        syncRegions: syncRegions,
        endpointANodeName: filtersByColumnName['endpointA.nodeName'],
        endpointBNodeName: filtersByColumnName['endpointB.nodeName'],
        linkStable: filtersByColumnName.linkStable,
        syncActive: filtersByColumnName.syncActive,
        isLinkPathDiffTrusted: filtersByColumnName.isLinkPathDiffTrusted,
        isAutoCalibrated: filtersByColumnName.isAutoCalibrated,
        isDefaultLinkOptionsOverriden: filtersByColumnName.isDefaultLinkOptionsOverriden,
        deleteProfilesOnAutoCalibration: filtersByColumnName.deleteProfilesOnAutoCalibration,
        deleteProfilesOnChange: filtersByColumnName.deleteProfilesOnChange,
        limit: queryParams.pageSize,
        offset: queryParams.pageSize * queryParams.page,
        orderBy: (queryParams.orderBy?.field as PersistedSyncLinkQuerySortableColumnsType) ?? 'name',
        orderDirection: queryParams.orderDirection || 'asc',
      };
      const result = await ttlinksApi.queryTimeTransferLinks({ query: apiQueryParams });
      if (result.status !== StatusCodes.OK) {
        throw errorFromWrappedError(result.status, result.body);
      }
      return {
        data: result.body.result,
        page: result.body.metadata.page,
        totalCount: result.body.metadata.totalCount,
      };
    },
    [ttlinksApi, syncRegions],
  );

  return fetcher;
};
