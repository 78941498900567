import React, { FunctionComponent } from 'react';
import { DscpCodePoints } from '../../constants';
import { LinkEndpoint } from './LinkEndpoint';

export const LinkEndpointsFormContent: FunctionComponent = () => {
  return (
    <>
      <datalist id="dscp-code-points">
        {DscpCodePoints.map(([val, name]) => (
          <option key={val} value={val} label={`${name} (${val})`} />
        ))}
      </datalist>
      <LinkEndpoint prefix="endpointA" label="Endpoint A" />
      <LinkEndpoint prefix="endpointB" label="Endpoint B" />
    </>
  );
};
