import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwr from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sConfigApiRef } from '@netinsight/management-app-common-react';

export const GlobalSettingsCacheKey = 'GlobalSettings';

export function useGlobalSettings() {
  const configApi = useApi(k8sConfigApiRef);

  const fetcher = useCallback(async () => {
    const result = await configApi.getGlobalSettings();
    if (result.status !== StatusCodes.OK) {
      throw errorFromWrappedError(result.status, (result as any).body);
    }

    return result.body;
  }, [configApi]);

  return useSwr(GlobalSettingsCacheKey, fetcher);
}
