import { useCallback } from 'react';
import StatusCodes from 'http-status-codes';
import useSWRMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import {
  apiPermission,
  errorFromWrappedError,
  k8sAlarmSubscriptionsApiRef,
} from '@netinsight/management-app-common-react';
import { AlarmSubscriptionSpec, alarmSubscriptionApi as contract } from '@netinsight/management-app-common-api';
import { AlarmSubscriptionCacheKey } from './useAlarmSubscriptions';

export const useAlarmSubscriptionCreate = () => {
  const alarmSubscriptionApi = useApi(k8sAlarmSubscriptionsApiRef);

  const createSubscription = useCallback(
    async (_: string, { arg: body }: { arg: AlarmSubscriptionSpec }) => {
      const result = await alarmSubscriptionApi.createAlarmSubscription({ body });
      if (result.status !== StatusCodes.OK) {
        throw errorFromWrappedError(result.status, (result as any).body);
      }
    },
    [alarmSubscriptionApi],
  );

  const mutation = useSWRMutation(AlarmSubscriptionCacheKey, createSubscription);
  return { ...mutation, permission: apiPermission(contract.createAlarmSubscription) };
};
