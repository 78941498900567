import React from 'react';
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { buttonPropsFromPermission, PermissionResult } from '@netinsight/management-app-common-react';
import { useGroupCalibrationSpec } from '../../../../../hooks';
import {
  GroupCalibrationPresetFormValues,
  newGroupTemplate,
  transformSelectedGroupPreset,
} from '../../../../../utils/calibrationHelpers';

export const GroupList = ({
  setSelectedGroup,
  selectedGroup,
  setFormMode,
  formMode,
  permission,
}: {
  setSelectedGroup: (group: GroupCalibrationPresetFormValues | null) => void;
  selectedGroup: GroupCalibrationPresetFormValues | null;
  setFormMode: (mode: 'add' | 'edit') => void;
  formMode: 'add' | 'edit';
  permission: PermissionResult;
}) => {
  const { data: spec } = useGroupCalibrationSpec();
  const buttonProps = buttonPropsFromPermission(permission);
  return (
    <Paper elevation={2}>
      <List>
        <ListItem>
          <ListItemText data-testid="group-list-title" primary={<Typography variant="subtitle1">Groups</Typography>} />
          <ListItemSecondaryAction>
            <Button
              onClick={() => {
                setFormMode('add');
                setSelectedGroup(newGroupTemplate);
              }}
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              disabled={buttonProps.disabled || formMode === 'add'}
              title={buttonProps.title}
              data-testid="add-group-button"
            >
              Add Group
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        {(spec?.presets ?? []).map(preset => (
          <ListItem
            button
            key={preset.name}
            data-testid={`preset-${preset.name}`}
            onClick={() => {
              setFormMode('edit');
              setSelectedGroup(transformSelectedGroupPreset(preset));
            }}
            selected={selectedGroup?.name === preset.name}
          >
            {preset.name}
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};
