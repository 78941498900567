import { useCallback } from 'react';
import StatusCodes from 'http-status-codes';
import useSWR, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sConfigApiRef } from '@netinsight/management-app-common-react';

export const useTimeNodeConfig = (nodeId: string, swrConfig?: SWRConfiguration) => {
  const configApi = useApi(k8sConfigApiRef);

  const getConfig = useCallback(async () => {
    const response = await configApi.getNodeTimeNodeConfig({ params: { nodeId } });
    if (response.status !== StatusCodes.OK) {
      throw errorFromWrappedError(response.status, (response as any).body);
    }
    return response.body.config;
  }, [configApi, nodeId]);

  return useSWR([nodeId, 'useTimeNodeConfig'], getConfig, swrConfig);
};
