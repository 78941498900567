import React from 'react';
import { Slider, SliderProps, makeStyles } from '@material-ui/core';
import { F, flow } from '@mobily/ts-belt';
import { InputTooltip } from '../InputTooltip';
import classNames from 'classnames';

const useSliderStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  label: { color: theme.palette.text.secondary, minWidth: '8rem', display: 'block', marginBottom: '2.75rem' },
  slider: {},
}));

export type SliderFieldProps = {
  label: React.ReactNode;
  description?: string;
  valueScale?: (val: number) => number;
  labelScale?: (val: number) => string;
} & SliderProps;

export const SliderField = ({
  label,
  description,
  marks,
  valueScale = F.identity,
  labelScale = flow(F.identity, String),
  ...props
}: SliderFieldProps) => {
  const styles = useSliderStyles();
  return (
    <div className={styles.container}>
      <div id={`${props.name}-label`} className={classNames(styles.label, 'slider-label')}>
        {label} {description ? <InputTooltip text={description} /> : null}
      </div>
      <Slider
        orientation="horizontal"
        getAriaLabel={labelScale}
        valueLabelFormat={labelScale}
        valueLabelDisplay="on"
        marks={marks}
        className={styles.slider}
        aria-labelledby={`${props.name}-label`}
        {...props}
      />
    </div>
  );
};
