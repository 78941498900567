import { createComponentExtension, createPlugin } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const globalSettingsUiPlugin = createPlugin({
  id: 'global-settings-ui',
  routes: {
    root: rootRouteRef,
  },
});

export const GlobalLinkOptionsPage = globalSettingsUiPlugin.provide(
  createComponentExtension({
    name: 'GlobalLinkOptionsPage',
    component: {
      lazy: () => import('./components').then(m => m.GlobalLinkOptionsForm),
    },
  }),
);

export const GlobalSSHKeysPage = globalSettingsUiPlugin.provide(
  createComponentExtension({
    name: 'GlobalSSHKeysPage',
    component: {
      lazy: () => import('./components').then(m => m.GlobalSSHKeys),
    },
  }),
);

export const GlobalLoggingConfigPage = globalSettingsUiPlugin.provide(
  createComponentExtension({
    name: 'GlobalLoggingConfigPage',
    component: {
      lazy: () => import('./components').then(m => m.GlobalLoggingConfigForm),
    },
  }),
);
