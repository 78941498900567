import React, { FunctionComponent, useEffect, useCallback } from 'react';
import { useController, useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider } from '@material-ui/core';
import { F } from '@mobily/ts-belt';
import {
  CheckboxField,
  getSelectOptionsFromSchema,
  TextField,
  useFormStyles,
  usePermission,
  useSubmitButtonProps,
  useTextFieldController,
  useTimeTransferConfigUpdate,
} from '@netinsight/management-app-common-react';
import { HiccConfig, HiccConfigSchema } from '@netinsight/crds-timetransfer';

export type TimeNodeHiccConfigFormProps = {
  initialValues: HiccConfig;
  defaultValues: HiccConfig;
  nodeId: string;
};

export const TimeNodeHiccConfigForm: FunctionComponent<TimeNodeHiccConfigFormProps> = ({
  initialValues,
  defaultValues,
  nodeId,
}) => {
  const formStyles = useFormStyles();
  const { control, reset, handleSubmit, formState } = useForm<HiccConfig>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: zodResolver(HiccConfigSchema),
  });

  const prevInitialValues = usePrevious(initialValues);
  useEffect(() => {
    if (!F.equals(prevInitialValues, initialValues)) {
      reset(initialValues, { keepSubmitCount: true, keepIsSubmitted: true, keepValues: true });
    }
  }, [reset, prevInitialValues, initialValues]);

  const { trigger: updateConfig, permission: updateConfigPermission } = useTimeTransferConfigUpdate(nodeId);
  const { isLoading: isLoadingPermission, ...permission } = usePermission(updateConfigPermission);
  const buttonProps = useSubmitButtonProps({ permission, formState });
  const handleOnSubmit = useCallback(
    async (updatedHiccConfig: HiccConfig) => {
      await updateConfig(existingConfig => ({
        ...existingConfig,
        hicc: updatedHiccConfig,
      }));
    },
    [updateConfig],
  );

  const handleResetToDefault = useCallback(() => {
    reset(defaultValues, { keepValues: false });
  }, [reset, defaultValues]);

  const { field: tenMhzBandwidthFieldProps } = useTextFieldController({
    control,
    schema: HiccConfigSchema.shape['10MBandwidth'],
    name: '10MBandwidth',
    label: '10M bandwidth (Hz)',
  });

  const { field: tenMhzDampingFieldProps } = useTextFieldController({
    control,
    schema: HiccConfigSchema.shape['10MDamping'],
    name: '10MDamping',
    label: '10M damping',
  });

  const { field: tenMhzMaxControlSignalFieldProps } = useTextFieldController({
    control,
    schema: HiccConfigSchema.shape['10MMaxControlSignal'],
    name: '10MMaxControlSignal',
    label: '10M max control signal',
  });

  const { field: ppsout10MArchFieldProps } = useTextFieldController({
    control,
    schema: HiccConfigSchema.shape.ppsout10MArchitecture,
    name: 'ppsout10MArchitecture',
    label: '10M/PPSOut architecture',
  });

  const enableTimeDifferenceProps = useController({ control, name: 'enableTimeDifference' });
  const enableTimeDifferencePercentilesProps = useController({
    control,
    name: 'enableTimeDifferencePercentiles',
  });

  const enableInfluxPpsInProps = useController({ control, name: 'enableInfluxPpsIn' });
  const enableInfluxTsProcBasicProps = useController({ control, name: 'enableInfluxTsProcBasic' });
  const enableInfluxTsProcDetailsProps = useController({ control, name: 'enableInfluxTsProcDetails' });

  const { field: linkMinAgeToBecomeStableProps } = useTextFieldController({
    schema: HiccConfigSchema.shape.linkMinAgeToBecomeStable,
    control,
    name: 'linkMinAgeToBecomeStable',
    label: 'Link min age to become stable (seconds)',
  });

  const { field: stabilityControlErrorToleranceProps } = useTextFieldController({
    schema: HiccConfigSchema.shape.stabilityControlErrorTolerance,
    control,
    name: 'stabilityControlErrorTolerance',
    label: 'Stability control error tolerance (seconds)',
  });

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} className={formStyles.formContainer}>
      <TextField {...linkMinAgeToBecomeStableProps} inputProps={{ step: 1 }} />
      <TextField {...stabilityControlErrorToleranceProps} inputProps={{ step: 1e-8 }} />
      <Divider />
      <TextField {...tenMhzBandwidthFieldProps} inputProps={{ step: 1e-2 }} />
      <TextField {...tenMhzDampingFieldProps} inputProps={{ step: 1e-2 }} />
      <TextField {...tenMhzMaxControlSignalFieldProps} inputProps={{ step: 1e-7 }} />
      <TextField {...ppsout10MArchFieldProps} select SelectProps={{ native: true }}>
        {getSelectOptionsFromSchema(HiccConfigSchema.shape.ppsout10MArchitecture.optional())}
      </TextField>
      <Divider />
      <CheckboxField fieldProps={enableTimeDifferenceProps} label="Enable time difference metrics collection" />
      <CheckboxField
        fieldProps={enableTimeDifferencePercentilesProps}
        label="Enable time difference percentiles metrics collection"
      />
      <CheckboxField fieldProps={enableInfluxPpsInProps} label="Enable extended PPS in metrics" />
      <CheckboxField fieldProps={enableInfluxTsProcBasicProps} label="Enable basic tsproc metrics" />
      <CheckboxField fieldProps={enableInfluxTsProcDetailsProps} label="Enable more tsproc metrics" />

      <div className={formStyles.buttonContainer}>
        <Button {...buttonProps} data-testid="btn-submit" />
        <Button
          type="button"
          size="large"
          color="default"
          variant="outlined"
          data-testid="btn-reset"
          onClick={handleResetToDefault}
          disabled={buttonProps.disabled}
        >
          Reset to default
        </Button>
      </div>
    </form>
  );
};
