import classNames from 'classnames';
import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import { useController } from 'react-hook-form';
import type { FieldPath, FieldValues, UseControllerProps, UseControllerReturn } from 'react-hook-form';
import { FormControlLabel, FormControlLabelProps, Radio, RadioProps, Typography } from '@material-ui/core';
import { G, O, pipe } from '@mobily/ts-belt';
import { InputTooltip } from '@netinsight/management-app-common-react';

export const useRadioSingleController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  defaultValue,
  ...controllerInputProps
}: UseControllerProps<TFieldValues, TName>): {
  field: Pick<RadioSingleProps, 'name' | 'currentValue' | 'onChange' | 'defaultValue'>;
} & Pick<UseControllerReturn<TFieldValues, TName>, 'fieldState' | 'formState'> => {
  const {
    field: { value: currentValue, onChange: innerOnChange },
    ...otherControllerProps
  } = useController({ name, ...controllerInputProps });
  const onChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const evtValStr = evt.target?.value;
      if (G.isNullable(evtValStr)) {
        return;
      }
      const isChecked = evt.target?.checked;
      const evtValue =
        pipe(
          O.fromExecution(() => JSON.parse(evtValStr)),
          O.toUndefined,
        ) ?? evtValStr;
      if (isChecked) {
        innerOnChange(evtValue);
      }
    },
    [innerOnChange],
  );

  return {
    field: {
      name,
      currentValue,
      defaultValue,
      onChange,
    },
    ...otherControllerProps,
  };
};

export type RadioSingleProps = Omit<RadioProps, 'value' | 'checked' | 'defaultValue'> & {
  value: any;
  defaultValue?: any;
  currentValue?: any;
  label: ReactNode;
  labelClassName?: string;
  description?: string;
  LabelProps?: Omit<FormControlLabelProps, 'label' | 'control'>;
};

export const RadioSingle: FunctionComponent<RadioSingleProps> = ({
  value,
  defaultValue,
  currentValue,
  label,
  labelClassName,
  description,
  color = 'primary',
  className,
  LabelProps,
  ...radioProps
}) => {
  const valueAsString = typeof value === 'string' ? value : JSON.stringify(value);
  const checked = currentValue === value;
  return (
    <FormControlLabel
      label={
        <>
          {label}{' '}
          {value === defaultValue ? (
            <Typography component="span" variant="body2">
              (default)
            </Typography>
          ) : null}
          {G.isNotNullable(description) ? <InputTooltip text={description} /> : null}
        </>
      }
      labelPlacement="end"
      className={classNames(className)}
      classes={{ ...LabelProps?.classes, label: classNames(LabelProps?.classes?.label, labelClassName) }}
      control={
        <>
          <Radio color={color} checked={checked} value={valueAsString} {...radioProps} />
        </>
      }
      {...LabelProps}
    />
  );
};
