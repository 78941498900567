import { useCallback } from 'react';
import StatusCodes from 'http-status-codes';
import useSWRMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import {
  apiPermission,
  errorFromWrappedError,
  k8sAlarmSubscriptionsApiRef,
} from '@netinsight/management-app-common-react';
import { alarmSubscriptionApi as contract } from '@netinsight/management-app-common-api';
import { AlarmSubscriptionCacheKey } from './useAlarmSubscriptions';

export const useAlarmSubscriptionDelete = () => {
  const alarmSubscriptionApi = useApi(k8sAlarmSubscriptionsApiRef);

  const deleteSubscription = useCallback(
    async (_: string, { arg: id }: { arg: string }) => {
      const result = await alarmSubscriptionApi.deleteAlarmSubscription({ params: { id } });
      if (result.status !== StatusCodes.OK) {
        throw errorFromWrappedError(result.status, (result as any).body);
      }
    },
    [alarmSubscriptionApi],
  );

  const mutation = useSWRMutation(AlarmSubscriptionCacheKey, deleteSubscription);
  return { ...mutation, permission: apiPermission(contract.deleteAlarmSubscription) };
};
