import { initContract } from '@ts-rest/core';
import {
  TTLinkEndpointSchema,
  TTLinkSpecSchema,
  TTLinkProfileSchema,
  PtpProfiles,
  G8275_2_MAX_CONNECTIONS_NO_OFFLOAD,
} from '@netinsight/crds-timetransfer';
import z, { ZodSchema } from 'zod';
import { getPaginatedQueryResultSchema, ServiceConfigSchema, WrappedErrorSchema } from './types';
import StatusCodes from 'http-status-codes';
import { timenodeCreate, timenodeDelete, timenodeRead, timenodeUpdate } from '@netinsight/zyntai-policy';

export const getDefaultUnicastMaxConnectionsValue = (profile: string, maxConnectionsTotal: number) =>
  profile === PtpProfiles.G8275_1 ? 1 : maxConnectionsTotal;

export const getDefaultLegacyUnicastMaxConnectionsValue = (profile: string) =>
  profile === PtpProfiles.G8275_1 ? 1 : G8275_2_MAX_CONNECTIONS_NO_OFFLOAD;

export const LinkStates = {
  Shutdown: 0,
  Listening: 1,
  HoldoffConnecting: 2,
  Connecting: 3,
  TCPConnected: 4,
  TLSHandshake: 5,
  UtilityNegotiation: 6,
  TimeChannelSetup: 7,
  TimestampsReceived: 8,
  TimestampsSentAcknowledged: 9,
  TimestampsSentInBothDirections: 10,
} as const;

export const LinkStateSchema = z.union([
  z.literal(LinkStates.Shutdown).describe('Shutdown'), // Link is removed
  z.literal(LinkStates.Listening).describe('Listening'), // Server end is waiting for a connection
  z.literal(LinkStates.HoldoffConnecting).describe('Holdoff connecting'), // Client end is waiting to do a connect attempt
  z.literal(LinkStates.Connecting).describe('Connecting'), // Client end is trying to connect
  z.literal(LinkStates.TCPConnected).describe('TCP connected'), // TCP connection established
  z.literal(LinkStates.TLSHandshake).describe('TLS handshake'), // TLS handshake ongoing
  z.literal(LinkStates.UtilityNegotiation).describe('Utility negotiation'), // Utility channel negotiation  (link id, names, tokens, sequence numbers)
  z.literal(LinkStates.TimeChannelSetup).describe('Time channel setup'), // Setup UDP socket in fpga and start sending
  z.literal(LinkStates.TimestampsReceived).describe('Timestamps received'), // The node receives timestamps
  z.literal(LinkStates.TimestampsSentAcknowledged).describe('Timestamps sent acknowledged'), // The timestamps sent from the node are acknowledged by the other end
  z.literal(LinkStates.TimestampsSentInBothDirections).describe('Timestamps sent in both directions'), // Time timestamp channel is running
]);

export type LinkState = z.infer<typeof LinkStateSchema>;

export const PersistedSyncLinkQueryResultItemEndpointSchema = TTLinkEndpointSchema.extend({
  nodeName: z.string().optional(),
  syncRegion: z.string().optional(),
  nodeStable: z.boolean().optional(),
});

export type PersistedSyncLinkQueryResultItemEndpoint = z.infer<typeof PersistedSyncLinkQueryResultItemEndpointSchema>;

export const PersistedSyncLinkQueryResultItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  endpointA: PersistedSyncLinkQueryResultItemEndpointSchema,
  endpointB: PersistedSyncLinkQueryResultItemEndpointSchema,
  linkState: LinkStateSchema.optional(),
  linkStable: z.boolean().optional(),
  syncActive: z.boolean().optional(),
  timeError: z.number().optional(),
  rtt: z.number().optional(),
  pathDiff: z.number().optional(),
  isAutoCalibrated: z.boolean().optional(),
  deleteProfilesOnChange: z.boolean().optional(),
  deleteProfilesOnAutoCalibration: z.boolean().optional(),
  isDefaultLinkOptionsOverriden: z.boolean().optional(),
  isLinkPathDiffTrusted: z.boolean().optional(),
});

export type PersistedSyncLinkQueryResultItem = z.infer<typeof PersistedSyncLinkQueryResultItemSchema>;

export const PersistedSyncLinkQuerySortableColumns = [
  'name',
  'endpointA.nodeName',
  'endpointB.nodeName',
  'linkStable',
  'syncActive',
  'timeError',
  'rtt',
  'pathDiff',
  'isAutoCalibrated',
  'deleteProfilesOnChange',
  'deleteProfilesOnAutoCalibration',
  'isDefaultLinkOptionsOverriden',
  'isLinkPathDiffTrusted',
] as const satisfies (
  | keyof Omit<PersistedSyncLinkQueryResultItem, 'endpointA' | 'endpointB'>
  | `${keyof Pick<PersistedSyncLinkQueryResultItem, 'endpointA' | 'endpointB'>}.${keyof PersistedSyncLinkQueryResultItemEndpoint}`
)[];

const PersistedSyncLinkQueryFilterableColumns = [
  'name',
  'endpointANodeName',
  'endpointBNodeName',
  'linkStable',
  'syncActive',
  'isAutoCalibrated',
  'deleteProfilesOnChange',
  'deleteProfilesOnAutoCalibration',
  'isDefaultLinkOptionsOverriden',
  'isLinkPathDiffTrusted',
  'syncRegions',
] as const satisfies (
  | keyof Omit<PersistedSyncLinkQueryResultItem, 'endpointA' | 'endpointB'>
  | `${keyof Pick<PersistedSyncLinkQueryResultItem, 'endpointA' | 'endpointB'>}${Capitalize<keyof PersistedSyncLinkQueryResultItemEndpoint>}`
  | 'syncRegions'
)[];

export type PersistedSyncLinkQueryFilterableColumnsType = (typeof PersistedSyncLinkQueryFilterableColumns)[number];

export const PersistedSyncLinkQuerySortableColumnsSchema = z.enum(PersistedSyncLinkQuerySortableColumns);

export type PersistedSyncLinkQuerySortableColumnsType = z.infer<typeof PersistedSyncLinkQuerySortableColumnsSchema>;

export const PersistedSyncLinkQueryParametersSchema = z
  .object({
    offset: z.coerce.number().default(0).optional(),
    limit: z.coerce.number().default(25).optional(),
    orderBy: PersistedSyncLinkQuerySortableColumnsSchema.optional(),
    orderDirection: z.enum(['asc', 'desc']).default('asc').optional(),
  })
  .extend({
    name: z.string().optional(),
    endpointANodeName: z.string().optional(),
    endpointBNodeName: z.string().optional(),
    linkStable: z.array(z.string()).optional(),
    syncActive: z.array(z.string()).optional(),
    isAutoCalibrated: z.array(z.string()).optional(),
    deleteProfilesOnChange: z.array(z.string()).optional(),
    deleteProfilesOnAutoCalibration: z.array(z.string()).optional(),
    isDefaultLinkOptionsOverriden: z.array(z.string()).optional(),
    isLinkPathDiffTrusted: z.array(z.string()).optional(),
    syncRegions: z.array(z.string()).optional(),
  } satisfies Record<PersistedSyncLinkQueryFilterableColumnsType, ZodSchema>);

export type PersistedSyncLinkQueryParameters = z.infer<typeof PersistedSyncLinkQueryParametersSchema>;

// Sync link (2-way)

export const UnresolvedSyncLinkSchema = TTLinkSpecSchema;
export type UnresolvedSyncLink = z.infer<typeof UnresolvedSyncLinkSchema>;

export const PersistedSyncLinkSchema = UnresolvedSyncLinkSchema.extend({
  id: z.string(),
});
export type PersistedSyncLink = z.infer<typeof PersistedSyncLinkSchema>;

// Link target(s) (1-way), i.e. to answer what nodes are this node connected to

export const UnresolvedLinkTargetSchema = TTLinkEndpointSchema.extend({
  linkId: z.void(),
  index: z.void(),
});
export type UnresolvedLinkTarget = z.infer<typeof UnresolvedLinkTargetSchema>;

export const PersistedLinkTargetSchema = TTLinkEndpointSchema.extend({
  linkId: z.string(),
  name: z.string().optional(),
  autoCalibration: z.boolean(),
  profiles: z.array(TTLinkProfileSchema),
  source: TTLinkEndpointSchema,
  index: z.union([z.literal(0), z.literal(1)]), // which side of the link the target is referenced in the Kubernetes CR object,
});
export type PersistedLinkTarget = z.infer<typeof PersistedLinkTargetSchema>;

export const LinkTargetSchema = z.union([UnresolvedLinkTargetSchema, PersistedLinkTargetSchema]);
export type LinkTarget = z.infer<typeof LinkTargetSchema>;

// Schema that allows adding new links (without any id)
export const UnresolvedLinkTargetListSchema = z.object({
  targets: z.array(LinkTargetSchema),
});
export type UnresolvedLinkTargetList = z.infer<typeof UnresolvedLinkTargetListSchema>;

export const PersistedLinkTargetListSchema = z.object({
  targets: z.array(PersistedLinkTargetSchema),
});
export type PersistedLinkTargetList = z.infer<typeof PersistedLinkTargetListSchema>;

const c = initContract();

export const ttLinksApi = c.router({
  queryTimeTransferLinks: {
    method: 'GET',
    path: '/cluster/ttlinks-query',
    summary: 'Query time transfer links',
    query: PersistedSyncLinkQueryParametersSchema,
    responses: {
      [StatusCodes.OK]: getPaginatedQueryResultSchema(PersistedSyncLinkQueryResultItemSchema),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.INTERNAL_SERVER_ERROR]: WrappedErrorSchema,
    },
    metadata: { permission: timenodeRead },
  },
  listTimeTransferLinks: {
    method: 'GET',
    path: '/cluster/ttlinks',
    summary: 'Get all time transfer links',
    responses: {
      [StatusCodes.OK]: z.object({ links: z.array(PersistedSyncLinkSchema) }),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.INTERNAL_SERVER_ERROR]: WrappedErrorSchema,
    },
    metadata: { permission: timenodeRead },
  },
  getTimeTransferLink: {
    method: 'GET',
    path: '/cluster/ttlinks/:id',
    summary: 'Get time transfer link',
    responses: {
      [StatusCodes.OK]: ServiceConfigSchema.extend({ config: PersistedSyncLinkSchema }),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
    },
    metadata: { permission: timenodeRead },
  },
  createTimeTransferLink: {
    method: 'POST',
    path: '/cluster/ttlinks',
    summary: 'Create time transfer link',
    body: UnresolvedSyncLinkSchema,
    responses: {
      [StatusCodes.OK]: ServiceConfigSchema.extend({ config: PersistedSyncLinkSchema }),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.BAD_REQUEST]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: WrappedErrorSchema,
    },
    metadata: { permission: timenodeCreate },
  },
  updateTimeTransferLink: {
    method: 'PUT',
    path: '/cluster/ttlinks/:id',
    summary: 'Update time transfer link',
    body: UnresolvedSyncLinkSchema,
    responses: {
      [StatusCodes.OK]: ServiceConfigSchema.extend({ config: PersistedSyncLinkSchema }),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.BAD_REQUEST]: WrappedErrorSchema,
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
    },
    metadata: { permission: timenodeUpdate },
  },
  deleteTimeTransferLink: {
    method: 'DELETE',
    path: '/cluster/ttlinks/:id',
    summary: 'Delete time transfer link',
    body: null,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
    },
    metadata: { permission: timenodeDelete },
  },

  getNodeLinkTargets: {
    method: 'GET',
    path: '/nodes/:nodeId/ttlinks/config',
    summary: 'Get time transfer link targets',
    responses: {
      [StatusCodes.OK]: ServiceConfigSchema.extend({ config: PersistedLinkTargetListSchema }),
      [StatusCodes.FORBIDDEN]: z.string(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
    },
    metadata: { permission: timenodeRead },
  },
});
