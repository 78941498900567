import React, { FunctionComponent, useCallback } from 'react';
import { UseFieldArrayAppend, useFormContext } from 'react-hook-form';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { TTLinkProfile } from '@netinsight/crds-timetransfer';
import { PersistedSyncLink } from '@netinsight/management-app-common-api';
import { Button, ButtonProps } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

export const LinkProfileCreateButton: FunctionComponent<
  {
    append: UseFieldArrayAppend<PersistedSyncLink, 'profiles'>;
  } & ButtonProps
> = ({ append, ...buttonProps }) => {
  const identityApi = useApi(identityApiRef);
  const { getValues } = useFormContext<PersistedSyncLink>();
  const handleNewProfile = useCallback(async () => {
    const userProfileInfo = await identityApi.getProfileInfo();
    const profileIndex = Math.max(...(getValues()?.profiles?.map(p => p.index) ?? 0), 0) + 1;
    const newLinkProfile: TTLinkProfile = {
      id: typeof crypto?.randomUUID === 'function' ? crypto.randomUUID() : '',
      index: profileIndex,
      selectable: true,
      delayFromRemote: 0,
      delayToRemote: 0,
      roundtripTime: 0,
      delayDifference: 0,
      comment: '',
      lastModified: new Date().toISOString(),
      createdBy: userProfileInfo.email ?? userProfileInfo.displayName,
    };
    append(newLinkProfile);
  }, [append, getValues, identityApi]);

  return (
    <Button
      {...buttonProps}
      variant="outlined"
      size="small"
      color="primary"
      type="button"
      data-testid="btn-add-profile"
      startIcon={<AddIcon />}
      onClick={handleNewProfile}
    >
      Add new profile
    </Button>
  );
};
