import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwrMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { TimeNodeSpec, configApi as contract } from '@netinsight/management-app-common-api';
import {
  apiPermission,
  errorFromWrappedError,
  k8sConfigApiRef,
  useSnackbarHelper,
} from '@netinsight/management-app-common-react';

export const useTimeNodeConfigUpdate = (nodeId: string, showNotification = true) => {
  const configApi = useApi(k8sConfigApiRef);
  const { snackbar } = useSnackbarHelper(!showNotification);
  const updateConfig = useCallback(
    async ([nodeIdParam]: [string, string], { arg: updater }: { arg: (existing: TimeNodeSpec) => TimeNodeSpec }) => {
      const existingConfigResult = await configApi.getNodeManagerConfig({ params: { nodeId: nodeIdParam } });

      let existingConfig: TimeNodeSpec;
      if (existingConfigResult.status === StatusCodes.OK) {
        existingConfig = existingConfigResult.body.config;
      } else if (existingConfigResult.status === StatusCodes.NOT_FOUND) {
        existingConfig = {};
      } else {
        throw errorFromWrappedError(existingConfigResult.status, (existingConfigResult as any).body);
      }
      const updatedConfig = updater(existingConfig);
      const updateResult = await configApi.setNodeTimeNodeConfig({
        params: { nodeId: nodeIdParam },
        body: updatedConfig,
      });
      if (updateResult.status !== StatusCodes.OK) {
        throw errorFromWrappedError(updateResult.status, (updateResult as any).body);
      }
      return updatedConfig;
    },
    [configApi],
  );
  const mutation = useSwrMutation([nodeId, 'useTimeNodeConfig'], updateConfig, {
    onSuccess: () => {
      snackbar.notifySuccess('Update');
    },
    onError: error => {
      snackbar.notifyError('Update', error.response, null);
    },
    populateCache: result => result,
  });

  return { ...mutation, permission: apiPermission(contract.setNodeTimeNodeConfig) };
};
