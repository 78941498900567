import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwrMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { apiPermission, errorFromWrappedError, k8sTTLinksApiRef } from '@netinsight/management-app-common-react';
import { PersistedSyncLink, ValidationError, ttLinksApi as contract } from '@netinsight/management-app-common-api';
import { TTLinksKey } from './useTTLinks';
import { isFieldErrors } from '../utils';
import { A, D, F, G, flow } from '@mobily/ts-belt';

const isNotNullableButEmpty = F.both<any>(G.isNotNullable, F.either(D.isEmpty, flow(D.values, A.every(G.isNullable))));

export const useTTLinkUpdate = () => {
  const ttLinksApi = useApi(k8sTTLinksApiRef);
  const update = useCallback(
    async (_: any, { arg: updatedLink }: { arg: PersistedSyncLink }) => {
      // avoid an error that occurs when the object is not null but empty
      if (isNotNullableButEmpty(updatedLink.options?.dynamicLinkChangeThresholdParams?.bad)) {
        delete updatedLink.options!.dynamicLinkChangeThresholdParams!.bad;
      }
      if (isNotNullableButEmpty(updatedLink.options?.dynamicLinkChangeThresholdParams?.good)) {
        delete updatedLink.options!.dynamicLinkChangeThresholdParams!.good;
      }
      if (isNotNullableButEmpty(updatedLink.options?.dynamicLinkChangeThresholdParams)) {
        delete updatedLink.options!.dynamicLinkChangeThresholdParams;
      }
      if (isNotNullableButEmpty(updatedLink.options)) {
        delete updatedLink.options;
      }
      const updateResponse = await ttLinksApi.updateTimeTransferLink({
        body: updatedLink,
        params: { id: updatedLink.id },
      });
      if (updateResponse.status === StatusCodes.OK) {
        return [updateResponse.body.config];
      }
      if (updateResponse.status === StatusCodes.BAD_REQUEST && isFieldErrors(updateResponse.body?.error?.details)) {
        throw new ValidationError(updateResponse.body.error.details);
      }
      throw errorFromWrappedError(updateResponse.status, updateResponse.body);
    },
    [ttLinksApi],
  );
  const mutation = useSwrMutation(TTLinksKey, update, {
    populateCache: ([updatedLinkFromServer], links) => {
      return links?.map(link => (link.id === updatedLinkFromServer.id ? updatedLinkFromServer : link)) ?? [];
    },
    revalidate: false,
  });

  return { ...mutation, permission: apiPermission(contract.updateTimeTransferLink) };
};
