import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwrMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { TimeNodeSyncRegion, syncRegionApi as contract } from '@netinsight/management-app-common-api';
import {
  apiPermission,
  errorFromWrappedError,
  k8sSyncRegionApiRef,
  useSnackbarHelper,
} from '@netinsight/management-app-common-react';
import { SyncRegionSwrKey } from './useSyncRegions';

export const useSyncRegionCreate = () => {
  const syncRegionApi = useApi(k8sSyncRegionApiRef);
  const { snackbar } = useSnackbarHelper();

  const create = useCallback(
    async (_: string, { arg: { name } }: { arg: Pick<TimeNodeSyncRegion, 'name'> }) => {
      const createdRegionResponse = await syncRegionApi.createSyncRegion({
        body: { id: '', name },
      });
      if (createdRegionResponse.status === StatusCodes.OK) {
        return [createdRegionResponse.body];
      }
      throw errorFromWrappedError(createdRegionResponse.status, createdRegionResponse.body);
    },
    [syncRegionApi],
  );
  const mutation = useSwrMutation(SyncRegionSwrKey, create, {
    populateCache: (createdRegions, regions) => [...(regions ?? []), ...(createdRegions ?? [])],
    revalidate: false,
    onSuccess: () => {
      snackbar.notifySuccess('Create');
    },
    onError: error => {
      snackbar.notifyError('Create', error.response, null);
    },
  });
  return { ...mutation, permission: apiPermission(contract.createSyncRegion) };
};
