import React from 'react';
import { Controller, Control, FieldValues, Path } from 'react-hook-form';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

export const ControlledAutocomplete = <T extends FieldValues>({
  control,
  name,
  onChangeModifier,
  componentProps = {
    options: [],
    renderInput: params => <TextField {...params} label={name} variant="outlined" fullWidth />,
  },
}: {
  name: Path<T>;
  onChangeModifier?: (newValue: any) => any;
  control: Control<T>;
  componentProps?: AutocompleteProps<any, any, any, any>;
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Autocomplete
            value={field.value ?? []}
            onChange={(_event, newValue) => {
              if (onChangeModifier) {
                return field.onChange(onChangeModifier(newValue));
              }
              return field.onChange(newValue);
            }}
            {...componentProps}
          />
        );
      }}
    />
  );
};
