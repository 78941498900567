import React, { FunctionComponent, useCallback } from 'react';
import { F } from '@mobily/ts-belt';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { usePermission, useSnackbarHelper, useSubmitButtonProps } from '@netinsight/management-app-common-react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFormStyles, TextField, useTextFieldController } from '@netinsight/management-app-common-react';
import { AlarmSubscriptionSpecSchema } from '@netinsight/crds';
import { z } from 'zod';
import classNames from 'classnames';
import { useAlarmSubscriptionCreate } from '../../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3),
  },
}));

type AlarmSubscriptionFormData = z.infer<typeof AlarmSubscriptionSpecSchema>;

const DefaultFormValues: AlarmSubscriptionFormData = {
  name: '',
  purpose: '',
  target: '',
  format: 'prometheus',
};

export const AlarmSubscriptionDrawerContent: FunctionComponent<{
  onClose: VoidFunction;
}> = ({ onClose = F.ignore }) => {
  const { trigger: createSubscription, permission: createSubscriptionPermission } = useAlarmSubscriptionCreate();
  const { isLoading, ...permission } = usePermission(createSubscriptionPermission);
  const { snackbar } = useSnackbarHelper();

  const formProps = useForm({
    defaultValues: DefaultFormValues,
    mode: 'onChange',
    resolver: zodResolver(AlarmSubscriptionSpecSchema),
  });
  const { control, handleSubmit, formState } = formProps;
  const buttonProps = useSubmitButtonProps({ permission, formState });

  const handleCreateOrUpdate = useCallback(
    async (submitData: AlarmSubscriptionFormData) => {
      await createSubscription(submitData, {
        onSuccess: () => snackbar.notifySuccess('Created subscription'),
        onError: () => snackbar.error('Subscription creation failed'),
      });
      onClose();
    },
    [createSubscription, onClose, snackbar],
  );

  const styles = useStyles();
  const formStyles = useFormStyles();

  const { field: nameInputProps } = useTextFieldController({
    control,
    name: 'name',
    label: 'Name',
    placeholder: 'Auto',
    description: 'Name for the subscription',
    schema: AlarmSubscriptionSpecSchema.shape.name,
  });

  const { field: purposeInputProps } = useTextFieldController({
    control,
    name: 'purpose',
    label: 'Purpose',
    placeholder: 'Auto',
    description: 'Freetext purpose for this subscription',
    schema: AlarmSubscriptionSpecSchema.shape.purpose,
  });

  const { field: targetInputProps } = useTextFieldController({
    control,
    name: 'target',
    label: 'Target',
    placeholder: 'Auto',
    description: 'Webhook to call',
    schema: AlarmSubscriptionSpecSchema.shape.target,
  });

  return (
    <FormProvider {...formProps}>
      <form
        onSubmit={handleSubmit(handleCreateOrUpdate as SubmitHandler<AlarmSubscriptionFormData>)}
        className={classNames(styles.container, formStyles.formContainer)}
        data-testid="alarm-subscription-add-form"
      >
        <TextField fullWidth {...nameInputProps} />
        <TextField fullWidth {...purposeInputProps} />
        <TextField fullWidth {...targetInputProps} />
        <div className={formStyles.buttonContainer}>
          <Button {...buttonProps} data-testid="btn-save-subscription">
            Create
          </Button>
          <Button
            variant="outlined"
            color="default"
            size="large"
            type="button"
            data-testid="btn-cancel-subscription"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
