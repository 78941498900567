import React, { useCallback, useEffect, useState } from 'react';
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { GroupList } from './components/GroupList';
import { GroupCalibrationPresetSchema } from '@netinsight/group-calibration-api';
import { useController, useForm } from 'react-hook-form';
import { ControlledAutocomplete } from './components/ControlledAutocomplete';
import { useSyncRegions } from '@netinsight/plugin-sync-region-ui';
import {
  CheckboxField,
  ConfirmButton,
  FeatureFlags,
  SliderField,
  TextField,
  useFormStyles,
  useNodeNameMap,
  usePermission,
  useSliderController,
  useSnackbarHelper,
  useSubmitButtonProps,
  useTextFieldController,
} from '@netinsight/management-app-common-react';
import classNames from 'classnames';
import RemoveIcon from '@material-ui/icons/CloseOutlined';
import { useGroupCalibrationSpec, useGroupCalibrationSpecUpdate } from '../../../../hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import {
  DefaultMaxAdjustmentNs,
  getNodeGroupOptions,
  getNodesFromSelectedRegions,
  GroupCalibrationPresetFormValues,
  IntervalLabels,
  IntervalMarks,
  intervalToSlider,
  IntervalValue,
  isIntervalAvailable,
  NodeNameMapWithRegion,
  NsToSec,
  prepareFormPreset,
  SpanMarks,
  spanToSlider,
} from '../../../../utils/calibrationHelpers';
import { FeatureFlagged } from '@backstage/core-app-api';

const useStyles = makeStyles({
  groupInfo: {
    padding: '20px',
  },
  form: {
    padding: '20px',
  },
  bold: {
    fontWeight: 'bold',
  },
  paddingOptions: {
    padding: '10px 20px 20px',
    height: '100%',
  },
  cardTitle: {
    fontSize: '18px',

    // fontWeight: 'bold',
  },
  titlePadding: {
    paddingBottom: '10px',
  },
  slider: {
    '& .slider-label': {
      marginBottom: '5px !important',
      marginTop: '20px',
    },
    '& div > span': {
      width: '98%',
      marginLeft: '1%',
    },
  },
  removeButton: {
    '& > button': {
      marginBottom: '10px',
    },
  },
});

export const CalibrationGroups = () => {
  const classes = useStyles();
  const formStyles = useFormStyles();
  const [selectedGroup, setSelectedGroup] = useState<GroupCalibrationPresetFormValues | null>(null);
  const { data: syncRegions = [] } = useSyncRegions();
  const { data: nodeNameMap = {} } = useNodeNameMap();
  const [formMode, setFormMode] = useState<'add' | 'edit'>('edit');
  const { trigger: updateSpec, permission: updatePermission } = useGroupCalibrationSpecUpdate(); // Hook to update the spec
  const { isLoading, ...permission } = usePermission(updatePermission);
  const { data: spec } = useGroupCalibrationSpec();
  const { snackbar } = useSnackbarHelper();

  const { control, reset, watch, setValue, formState, handleSubmit } = useForm<GroupCalibrationPresetFormValues>({
    resolver: zodResolver(
      GroupCalibrationPresetSchema.extend({
        name: GroupCalibrationPresetSchema.shape.name
          .min(1)
          .regex(/^[A-Za-z0-9 ]*$/, {
            message: 'Name contains unrecognized symbols',
          })
          .refine(
            name =>
              spec?.presets.filter(x => x.name !== selectedGroup?.name).findIndex(preset => preset.name === name) ===
              -1,
            {
              message: 'Name must be unique',
            },
          ),
      }),
    ),
    defaultValues: {
      name: '', // Default initial state of your form
      nodeGroups: [],
      includeNodes: [],
      excludeNodes: [],
      limitMaxAdjustment: false,
      maxAdjustmentNs: DefaultMaxAdjustmentNs,
      requireReferences: false,
      limitPathdiffToRtt: false,
      constrained: false,
      calibrateUnusedLinks: false,
      schedule: {
        interval: 'off',
        span: [0, 24],
        commit: false,
      },
    },
  });
  const submitButtonProps = useSubmitButtonProps({ permission, formState });

  const formNodeGroupsValue = watch('nodeGroups');
  const formIncludeNodesValue = watch('includeNodes');
  const formExcludeNodesValue = watch('excludeNodes');
  const formLimitMaxAdjustmentValue = watch('limitMaxAdjustment');
  const maxAdjustmentNsValue = watch('maxAdjustmentNs');
  const formSpanValue = watch('schedule.span');
  const formIntervalValue = watch('schedule.interval');

  useEffect(() => {
    // set the maxadjustment value on demand when maxadjustmentns changes
    if (maxAdjustmentNsValue) {
      setValue(
        'maxAdjustment',
        (maxAdjustmentNsValue ?? DefaultMaxAdjustmentNs) * NsToSec, // Convert ns to seconds
      );
    }
  }, [maxAdjustmentNsValue, setValue]);

  const handleSubmitFunction = useCallback(
    async (formValues: GroupCalibrationPresetFormValues) => {
      if (!spec) {
        snackbar.error('Failed to load calibration groups specification.');
        return;
      }

      if (!selectedGroup) {
        snackbar.error('No group selected.');
        return;
      }

      const existingGroupIndex = spec.presets.findIndex(preset => preset.name === selectedGroup.name);

      try {
        if (formMode === 'add' || existingGroupIndex === -1) {
          // Adding a new group
          await updateSpec({
            ...spec,
            presets: [...spec.presets, formValues],
          });
          snackbar.success(`Group "${formValues.name}" has been created successfully.`);
        } else {
          // Updating an existing group
          const updatedPresets = [...spec.presets];
          updatedPresets[existingGroupIndex] = formValues;

          await updateSpec({ ...spec, presets: updatedPresets });
          snackbar.success(`Group "${formValues.name}" has been updated successfully.`);
        }
        setSelectedGroup(formValues);
        // Reset state and form
        setFormMode('edit');
      } catch (error) {
        snackbar.error(`Failed to save the group: ${(error as { message?: string })?.message ?? 'Unknown error'}`);
      }
    },
    [spec, formMode, snackbar, updateSpec, selectedGroup],
  );

  const handleRemove = useCallback(async () => {
    if (!spec || !selectedGroup) {
      snackbar.error('No group selected or failed to load calibration groups specification.');
      return;
    }

    try {
      // Remove the selected group from `spec.presets`
      const updatedPresets = spec.presets.filter(preset => preset.name !== selectedGroup.name);

      // Call the update function to save changes
      await updateSpec({ ...spec, presets: updatedPresets });

      // Reset the state and show a success message
      setSelectedGroup(null); // Clear the selected group
      setFormMode('edit'); // Switch back to "edit" mode (default)
      reset(); // Clear the form
      snackbar.success(`Group "${selectedGroup.name}" has been removed successfully.`);
    } catch (error) {
      snackbar.error(`Failed to remove the group: ${(error as { message?: string })?.message ?? 'Unknown error'}`);
    }
  }, [spec, selectedGroup, snackbar, updateSpec, reset]);

  const invertedSpan = useCallback(() => {
    return (formSpanValue && formSpanValue[0] > formSpanValue[1]) ?? false;
  }, [formSpanValue]);

  const invertSpan = useCallback(
    (e: { target: { checked: any } }) => {
      if (formSpanValue && formSpanValue.length) {
        setValue(
          'schedule.span',
          _.cloneDeep(
            formSpanValue.sort((a, b) => {
              if (!e.target.checked) {
                return a - b;
              }
              return b - a;
            }),
          ),
          {
            shouldDirty: true,
          },
        );
      }
    },
    [formSpanValue, setValue],
  );

  const { field: groupCalibrationFormNameProps } = useTextFieldController({
    control,
    name: 'name',
    label: 'Name',
  });

  const { field: maxAdjustmentNsProps } = useTextFieldController({
    control,
    name: 'maxAdjustmentNs',
  });

  const limitMaxAdjustmentProps = useController({ control, name: 'limitMaxAdjustment' });
  const requireReferencesProps = useController({ control, name: 'requireReferences' });
  const limitPathdiffToRttProps = useController({ control, name: 'limitPathdiffToRtt' });
  const calibrateUnusedLinksProps = useController({ control, name: 'calibrateUnusedLinks' });
  const constrainedCalibrationProps = useController({ control, name: 'constrained' });
  const scheduleCommitProps = useController({ control, name: 'schedule.commit' });

  const intervalSliderProps = useSliderController({
    control,
    name: 'schedule.interval',
    label: 'Interval',
    min: 0,
    max: IntervalMarks.length - 1,
    marks: IntervalMarks,
    fromValueScale: (val: number | number[]) => {
      return intervalToSlider(val as unknown as IntervalValue);
    },
    // @ts-expect-error it expects number, this is ignoring the type
    toValueScale: (val: number) => {
      return IntervalLabels[val as number].id as unknown as IntervalValue;
    },
    step: 1,
  });

  const timespanSliderProps = useSliderController({
    control,
    name: 'schedule.span',
    label: (
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>Time Span (UTC)</Grid>
        <Grid item>
          <FormControlLabel
            disabled={!isIntervalAvailable(formIntervalValue)}
            control={
              <Switch size="small" onChange={invertSpan} checked={invertedSpan()} name="checkedB" color="primary" />
            }
            label="Invert"
          />
        </Grid>
      </Grid>
    ),
    min: 0,
    max: 24,
    // inverted span
    marks: SpanMarks,
    step: 1,
    isRange: true,
    fromValueScale: (val: number | number[]) => {
      // @ts-expect-error Due to custom hook normal
      return spanToSlider(Array.isArray(val) ? val : [val]);
    },
    toValueScale: (val: number | number[]) => {
      const values = val as number[];
      const [larger, smaller] = values[0] > values[1] ? [values[0], values[1]] : [values[1], values[0]];
      return invertedSpan() ? [larger, smaller] : [smaller, larger];
    },
  });

  useEffect(() => {
    if (selectedGroup) {
      reset(prepareFormPreset(selectedGroup));
    }
  }, [selectedGroup, reset]);

  useEffect(() => {
    if (!formNodeGroupsValue) return;

    // if the excluded nodeids are not part of a base group selected then remove them
    const nodesFromSelectedRegions = getNodesFromSelectedRegions(syncRegions, formNodeGroupsValue);

    if (formNodeGroupsValue?.length) {
      if (!formNodeGroupsValue?.includes('all') && formExcludeNodesValue?.length) {
        const _excludeNodes = formExcludeNodesValue.filter(nodeId => nodesFromSelectedRegions.includes(nodeId));

        if (!_.isEqual(_excludeNodes, formExcludeNodesValue)) {
          setValue('excludeNodes', _excludeNodes);
        }
      }
      if (formIncludeNodesValue?.length) {
        let _includeNodes = formIncludeNodesValue;

        // Filter out include nodes that are part of nodesFromSelectedRegions
        _includeNodes = _includeNodes.filter(nodeId => !nodesFromSelectedRegions.includes(nodeId));

        if (!_.isEqual(_includeNodes, formIncludeNodesValue)) {
          setValue('includeNodes', _includeNodes);
        }
      }
    }
  }, [formNodeGroupsValue, formExcludeNodesValue, formIncludeNodesValue, syncRegions, setValue]);

  const getNodeGroupOptionLabel = useCallback(
    (id: string) => {
      if (!syncRegions || !id) {
        return 'Unknown';
      }
      if (id === 'all') {
        return 'All Nodes';
      }
      return `Sync Region: ${syncRegions.find(region => region.name === id)?.name || id}`;
    },
    [syncRegions],
  );

  const getIncludeNodeOptions = (showIncludeNodes: boolean = true) => {
    if (formNodeGroupsValue === undefined || !nodeNameMap) {
      return [];
    }
    const nodesWithRegions: NodeNameMapWithRegion[] = [];
    (syncRegions ?? [])
      .filter(region => {
        if (!formNodeGroupsValue.length) {
          return true;
        }
        if (showIncludeNodes && formNodeGroupsValue.includes('all')) {
          return false;
        }
        if (!showIncludeNodes && formNodeGroupsValue.includes('all')) {
          return true;
        }
        if (!showIncludeNodes) {
          return formNodeGroupsValue.includes(region.name);
        }
        return !formNodeGroupsValue.includes(region.name);
      })
      .forEach(region => {
        const { name, nodeIds, id } = region;
        nodeIds.forEach(nodeId => {
          nodesWithRegions.push({
            nodeId,
            regionName: name,
            regionId: id,
            nodeName: nodeNameMap[nodeId],
          });
        });
      });
    return nodesWithRegions;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={2} lg={3} md={4}>
        <GroupList
          setSelectedGroup={setSelectedGroup}
          selectedGroup={selectedGroup}
          formMode={formMode}
          setFormMode={setFormMode}
          permission={permission}
        />
      </Grid>
      <Grid item xs={12} md={8} xl={10} lg={9}>
        <Paper elevation={2}>
          {!selectedGroup && (
            <Alert className={classes.groupInfo} variant="outlined" severity="info">
              Please select a group to view and edit its details. You can also add a new group by clicking the Add Group
              button.
            </Alert>
          )}
          {selectedGroup && (
            <div className={classes.form}>
              <form onSubmit={handleSubmit(handleSubmitFunction)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Breadcrumbs>
                      <Typography color="textSecondary">Groups</Typography>
                      <Typography color="textPrimary">{selectedGroup.name}</Typography>
                    </Breadcrumbs>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Paper elevation={1} className={classes.paddingOptions}>
                      <TextField {...groupCalibrationFormNameProps} fullWidth data-testid="groupform-name" />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography className={classNames(classes.cardTitle, classes.titlePadding)}>
                          Group & Node Selection
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <ControlledAutocomplete
                              name="nodeGroups"
                              control={control}
                              componentProps={{
                                getOptionLabel: (option: string) => getNodeGroupOptionLabel(option),
                                limitTags: 3,
                                multiple: true,
                                renderInput: params => {
                                  return (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      label="Base Group"
                                      data-testid="groupform-base"
                                    />
                                  );
                                },
                                options: getNodeGroupOptions(syncRegions),
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <ControlledAutocomplete
                              name="includeNodes"
                              control={control}
                              onChangeModifier={(values: NodeNameMapWithRegion[] | string[]) =>
                                values.map((x: NodeNameMapWithRegion | string) =>
                                  typeof x === 'object' ? x.nodeId : x,
                                )
                              }
                              componentProps={{
                                getOptionSelected: (option: NodeNameMapWithRegion, value: string) =>
                                  option.nodeId === value,
                                getOptionLabel: (option: NodeNameMapWithRegion | string) => {
                                  return typeof option !== 'string' ? option?.nodeName : nodeNameMap[option];
                                },
                                multiple: true,
                                limitTags: 3,
                                disabled: !getIncludeNodeOptions(true).length,
                                groupBy: (option: any) => option.regionName,
                                options: getIncludeNodeOptions(true),
                                renderInput: params => {
                                  return (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      label="Include Nodes"
                                      data-testid="groupform-include"
                                      helperText="Select the nodes to include in addition to the base. This field is disabled if 'All Nodes' is selected as the base or if there are no nodes to include in the remaining regions."
                                    />
                                  );
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <ControlledAutocomplete
                              name="excludeNodes"
                              control={control}
                              onChangeModifier={(values: NodeNameMapWithRegion[] | string[]) =>
                                values.map((x: NodeNameMapWithRegion | string) =>
                                  typeof x === 'object' ? x.nodeId : x,
                                )
                              }
                              componentProps={{
                                getOptionLabel: (option: NodeNameMapWithRegion | string) => {
                                  return typeof option !== 'string' ? option?.nodeName : nodeNameMap[option];
                                },
                                getOptionSelected: (option: NodeNameMapWithRegion, value: string) =>
                                  option.nodeId === value,
                                multiple: true,
                                limitTags: 3,
                                disabled: !formNodeGroupsValue?.length || !getIncludeNodeOptions(false).length,
                                options: getIncludeNodeOptions(false),
                                groupBy: (option: any) => option.regionName,
                                renderInput: params => {
                                  return (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      label="Exclude Nodes"
                                      data-testid="groupform-exclude"
                                      helperText="Select nodes to exclude from selected base. If there is no node to exclude in selected regions this field will be disabled."
                                    />
                                  );
                                },
                              }}
                            />
                          </Grid>
                          <Grid item sm={12} lg={4} xl={5}>
                            <Paper elevation={1} variant="outlined" className={classes.paddingOptions}>
                              <CheckboxField
                                fieldProps={limitMaxAdjustmentProps}
                                label="Limit Max Adjustment"
                                color="primary"
                                data-testid="groupform-limitmaxadjustment"
                              />
                              <TextField
                                {...maxAdjustmentNsProps}
                                label="Max Adjustment"
                                type="number"
                                disabled={!formLimitMaxAdjustmentValue}
                                data-testid="groupform-maxadjustmentns"
                                unit="ns"
                              />
                            </Paper>
                          </Grid>
                          <Grid item sm={12} lg={8} xl={7}>
                            <Paper elevation={1} variant="outlined" className={classes.paddingOptions}>
                              <CheckboxField
                                fieldProps={requireReferencesProps}
                                label="Require references on all nodes"
                                color="primary"
                                data-testid="groupform-requirereferences"
                              />
                              <CheckboxField
                                fieldProps={limitPathdiffToRttProps}
                                label="Limit path difference adjustment to RTT"
                                color="primary"
                                data-testid="groupform-limitpathdifftortt"
                              />
                              <CheckboxField
                                fieldProps={calibrateUnusedLinksProps}
                                label="Calibrate unused links"
                                color="primary"
                                data-testid="groupform-calibrateunusedlinks"
                              />
                              <FeatureFlagged with={FeatureFlags.ShowExperimentalFeatures}>
                                <CheckboxField
                                  fieldProps={constrainedCalibrationProps}
                                  label="Constrained calibration"
                                  color="primary"
                                  data-testid="groupform-constrainedcalibration"
                                />
                              </FeatureFlagged>
                            </Paper>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography className={classes.cardTitle}>Schedule</Typography>
                        <Typography variant="caption">
                          Configure an automatic calibration schedule for a group of nodes. The calculated link
                          adjustments will only be applied if the commit option is selected, else no changes are made.
                          Calibrations will be run at the start time of the Time Span selection and then at every
                          Interval up to and including the end of the Time Span selection.
                        </Typography>
                        <div className={classes.slider}>
                          <SliderField {...intervalSliderProps} valueLabelDisplay="off" />
                        </div>
                        <div className={classes.slider}>
                          <SliderField
                            {...timespanSliderProps}
                            valueLabelDisplay="auto"
                            track={invertedSpan() ? 'inverted' : undefined}
                            disabled={!isIntervalAvailable(formIntervalValue)}
                          />
                        </div>
                        <CheckboxField
                          fieldProps={scheduleCommitProps}
                          label="Commit scheduled calibration results"
                          color="primary"
                          disabled={!isIntervalAvailable(formIntervalValue)}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={10}>
                    <div className={formStyles.buttonContainer}>
                      <Button {...submitButtonProps} data-testid="groupform-submit">
                        {formMode === 'edit' && 'Apply'}
                        {formMode === 'add' && 'Create'}
                      </Button>
                      {formMode === 'edit' && (
                        <ConfirmButton
                          type="button"
                          variant="outlined"
                          color="secondary"
                          startIcon={<RemoveIcon />}
                          confirmation="This calibration group will be removed, do you want to continue?"
                          onClick={handleRemove}
                          disabled={submitButtonProps.disabled}
                          title={submitButtonProps.title}
                          data-testid="remove-group-button"
                        >
                          Remove group
                        </ConfirmButton>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </form>
            </div>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
